import Navbar from "../Nav-Bar/nav-bar.component";
import Details from "./steps/details.component";
import Price from "./steps/price.component";
import Contact from "./steps/contact.component";
import Final from "./steps/final.component";
import StepperControl from "./stepper.control.component";
import { Navigate } from 'react-router-dom';
import { UseContextProvider } from "./stepper.context.component";
import Stepper from "./stepper.component";
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Preview from "./steps/preview.component";
import { useNavigate } from 'react-router-dom';

// REDUX
import { connect } from 'react-redux';
import { newCreateGig } from '../../actions/gigs';
import setAlert from '../../actions/alert';
import { useForm } from "react-hook-form";
import { useStepperContext } from "./stepper.context.component";
import { Footer } from "../Footer/footer.component";



const Create = ({ isAuthenticated, setAlert, newCreateGig }) => {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const { userData, setUserData } = useStepperContext();
    const [currentStep, setCurrentStep] = useState(1);
    const [files, setFiles] = useState('');
    const [LocationError, setLocationError] = useState(false)
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        title: '',
        category: '',
        description: '',
        conditions: '',
        photolist: null,
        location: '',
        city: '',
        zip: '',
        hour: '',
        day: '',
        week: '',
        duration: '',
        status: ''
    });

  

    const steps = [
        "Beschreibung",
        "Kontakt",
        "Abschluss",
    ];

    const displayStep = (step) => {
        switch (step) {
            case 1:
                return <Details form={useForm} />;
            case 2:
                return <Contact />;
            case 3:
                return <Final />;
            default:
        }
    };

    //Check description and conditions empty
    var regex = /(<([^>]+)>)/ig


    const isValidTitle = userData.title != ""
    const isValidcategory = userData.category != ""
    const isValiddescription = !!userData.description.replace(regex, "").length;
    const isValidconditions = !!userData.conditions.replace(regex, "").length;
    const isValidzip = userData.zip != "" || userData.zip == undefined
    const isValidcanton = userData.zip != ""
    const isValidcity = userData.city != ""
    const isValidlat = userData.zip != ""
    const isValidlon = userData.zip != ""
    const isValidhour = userData.hour != ""
    const isValidday = userData.day != ""
    const isValidweek = userData.week != ""
    const isValidduration = userData.duration != ""
    const isValidCity = userData.city != ""
    const isValidCountry = userData.city != ""

    const GeoEncode = async (street, zipcode) => {

        if (userData['zip'] != "") {
            try {
               // const response = await fetch('http://dev.virtualearth.net/REST/v1/Locations?culture=de-CH&postalCode=' + zipcode + '&key=ApIQQg8l55-f_upDvPCRAbftZGcnAZRe0NpUrl-WEu7OBm7MhgLcYo44O4YiBhXO&countryRegion=CH');
                const response = await fetch('https://api.geoapify.com/v1/geocode/search?text=' + zipcode + ',Switzerland&lang=de&limit=1&type=postcode&format=json&apiKey=34f89ffc48ce4e66ae7762ed904208b7');

                const data = await response.json();
                console.log(data)

                userData['canton'] = data.results[0].state
                userData['lat'] = data.results[0].lat
                userData['lon'] = data.results[0].lon
                userData['city'] = data.results[0].county
                userData['country'] = data.results[0].country

                console.log(userData)


                if (userData['country'] != 'Schweiz') {
                    setAlert('Postleitzahl nicht in der Schweiz gefunden!', 400, 'error');
                }

                return 0;
            } catch (error) {
                setLocationError(true)
                return console.warn(error);
            }
        }
    }



    if (currentStep === steps.length && !LocationError) {
        newCreateGig(userData)
    }


    const handleClick = (direction) => {
        if (currentStep == 1) {

            if (!isValidTitle || !isValidcategory ||
                !isValidcategory || !isValiddescription || 
                !isValidzip || !isValidlat || !isValidlon || !isValidcanton || !isValidhour || (userData['fileList'] == undefined) || (userData['fileList'].length == 0) || userData['zip'] == undefined) {

                if (!isValidTitle) {
                    setAlert('Titel fehlt!', 400, 'error');
                }
                if (!isValidcategory) {
                    setAlert('Bitte wähle eine Kategorie aus!', 400, 'error');
                }
                if (!isValiddescription) {
                    setAlert('Beschreibung fehlt!', 400, 'error');

                }

                if (!isValidzip) {
                    setAlert('Postleitzahl fehlt!', 400, 'error');
                }

                if (!isValidhour) {
                    setAlert('Stundenmiete fehlt!', 400, 'error');
                }

                if (userData['zip'] == undefined) {
                    setAlert('Postleitzahl fehlt!', 400, 'error');
                }
                if ((userData['fileList'] == undefined) || (userData['fileList'].length == 0)) {
                    console.log(userData['fileList'])
                    setAlert('Mindestens 1 Bild hochladen!', 400, 'error');
                }

                return
            }
        }


        if (currentStep === 3) {
            newCreateGig(userData)
            return
        }




        let newStep = currentStep;

        direction === "next" ? ++newStep : --newStep;
        // check if steps are within bounds
        newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);

    };






    //https://stackoverflow.com/questions/43638938/updating-an-object-with-setstate-in-react





    if (isAuthenticated) {

        return (
            <div class="py-8 overflow-hidden">
                {/*https://github.com/QuickNuggets/multi-step-form*/}
                <div className="mx-auto  bg-white shadow-xl pb-2 border md:w-1/2">
                    {/* Stepper */}
                    <div className="horizontal container mt-5 ">
                        <Stepper steps={steps} currentStep={currentStep} />

                        <div className="p-2 ">

                            {displayStep(currentStep)}

                        </div>
                    </div>

                    {/* navigation button */}
                    {currentStep !== steps.length && (
                        <StepperControl
                            handleClick={handleClick}
                            currentStep={currentStep}
                            steps={steps}
                        />
                    )}

                </div>
                <Footer></Footer>

            </div>

        )

    }



}

Create.propTypes = {
    isAuthenticated: PropTypes.bool,
    setAlert: PropTypes.func.isRequired,
    newCreateGig: PropTypes.func.isRequired,
};



const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,

});

export default connect(mapStateToProps, { newCreateGig, setAlert })(Create);