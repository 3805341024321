import React from 'react'
import NavBarComponent from '../Nav-Bar/nav-bar.component'
import { Footer } from '../Footer/footer.component'

function Cookie() {
    return (
        <div>
            <div>
                <NavBarComponent></NavBarComponent>

                <section className="dark:bg-gray-800 dark:text-gray-100">
                    <div className="container flex flex-col justify-start p-4 mx-auto md:p-8 text-left">
                        <h2 className="mb-12 text-4xl font-bold leading-none text-center sm:text-5xl">Cookie-Richtlinien</h2>
                        <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 divide-gray-700 ">
                            <details open="Open">
                                <summary className="py-2 text-2xl font-bold outline-none cursor-pointer focus:underline">Cookies</summary>
                                <div className="pb-4">
                                    <p class="text-lg">Diese Website verwendet Cookies. Dabei handelt es sich um kleine Textdateien, die es ermöglichen, spezifische, auf den Nutzer bezogene Informationen auf dem Endgerät des Nutzers zu speichern, während der Nutzer die Website nutzt. Cookies ermöglichen es insbesondere, die Nutzungshäufigkeit und die Anzahl der Nutzer der Seiten zu ermitteln, Verhaltensmuster der Seitennutzung zu analysieren, aber auch, unser Angebot kundenfreundlicher zu gestalten. Cookies bleiben über das Ende einer Browser-Sitzung hinaus gespeichert und können bei einem erneuten Besuch der Seite wieder abgerufen werden. Wenn Sie dies nicht wünschen, sollten Sie Ihren Internet-Browser so einstellen, dass er die Annahme von Cookies verweigert.

                                        Ein genereller Widerspruch gegen die Verwendung von Cookies zu Online-Marketing-Zwecken kann für eine Vielzahl der Dienste, insbesondere beim Tracking, über die US-Seite http://www.aboutads.info/choices/ oder die EU-Seite http://www.youronlinechoices.com/ erklärt werden. Darüber hinaus kann die Speicherung von Cookies durch Deaktivierung in den Browsereinstellungen erreicht werden. Bitte beachten Sie, dass in diesem Fall nicht alle Funktionen dieses Online-Angebots genutzt werden können.</p>
                                </div>
                               
                            </details>

                        </div>
                    </div>
                </section>

                <Footer></Footer>


            </div>
        </div>
    )
}

export default Cookie
