import { useStepperContext } from "../stepper.context.component";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function Contact( {reduxEmail}) {
  const { userData, setUserData } = useStepperContext();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  return (
    <div className="flex flex-col ">
      <div className="mx-2 w-full flex-1">
        <div className="mt-3 h-6 text-md  text-left font-bold leading-8 text-gray-500">
          Deine Email-Addresse
        </div>
        <div className="my-2 flex rounded border border-gray-200 bg-white p-1">
          <input
            onChange={handleChange}
            value={reduxEmail}
            name="email"
            className="w-full appearance-none p-1 px-2 text-gray-800 outline-none"
            disabled="disabled"
          />
        </div>
      </div>    
      <div className="mx-2 w-full flex-1">
        <div className="mt-3 h-6 text-md  text-left font-bold leading-8 text-gray-500">
          Möchtest du deine Telefonnummer angeben ? (Optional)
        </div>
        
        <div className="my-2 flex rounded border border-gray-200 bg-white p-1">
          <input
            onChange={handleChange}
            value={userData["phone"] || ""}
            name="phone"
            className="w-full appearance-none p-1 px-2 text-gray-800 outline-none"
          />
        </div>
      </div>    
    </div>
  );
}

Contact.propTypes = {
  isAuthenticated: PropTypes.bool,
  reduxEmail: PropTypes.string
};



const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  reduxEmail: state.auth.email
});

export default connect(mapStateToProps,) (Contact)