import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import CookieConsent from "react-cookie-consent";
import PasswordResetComponent from './Components/auth/PasswordReset.component';
import { Provider } from 'react-redux';
import LoginForm from './Components/auth/LoginForm';
import Home from './Components/Home/home.component';
import { loadUser } from './actions/auth'; // added
import Register from './Components/Register/register.component';
import { Helmet } from "react-helmet";
import React from 'react';
import store from "../src/store";
import Alert from './layouts/Alert';
import Listing from './Components/Listing/listing.component';
import CreateContext from './Components/Create/create.context.component';
import MapTest from './Components/Map/mapTest';
import ProductWrapper from './Components/Product/product.wrapper.component';
import ProfilWrapper from './Components/Profil/profil.wrapper.component';
import Favorites from './Components/Favorites/favorites.component';
import FAQ from './Components/faq/faq.component';
import Privacy from './Components/terms/privacy.component';
import Cookie from './Components/cookie/cookie.components';
import EditContext from './Components/Edit/edit.context.component';
import Impressum from './Components/Footer/impressum.component';
import AGB from './Components/Footer/agb.component'
import PasswordForm from './Components/auth/PasswordForm';
import CheckMail from './Components/auth/CheckMail';
import VerifiedMail from './Components/auth/VerifiedMail';
import PasswordResetSuccess from './Components/auth/PasswordResetSuccess';
import PasswordSend from './Components/auth/PasswordSend';
import MyInquiries from './Components/MyInquiries/myinquiries.component';
import Regeln from './Components/Footer/regeln.component';
import { HelmetProvider } from 'react-helmet-async';

class App extends React.Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }

  state = { markerPosition: { lat: 49.8419, lng: 24.0315 } };

  render() {
    const { markerPosition } = this.state;
    return (
      <HelmetProvider>
        <Provider store={store}>
          <div className="App">
            <Alert />
            <Router>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/product/:slug" element={<ProductWrapper />} />
                <Route exact path="/profile" element={<ProfilWrapper />} />
                <Route exact path="/login" element={<LoginForm />} /> // added
                <Route exact path="/passwordreset" element={<PasswordResetComponent />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/create" element={<CreateContext />} />
                <Route exact path="/edit/:slug" element={<EditContext />} />
                <Route exact path="/list/:slug" element={<Listing />} />
                <Route exact path="/map/" element={< MapTest markerPosition={markerPosition} />} />
                <Route exact path="/favorites/" element={<Favorites />} />
                <Route exact path="/faq/" element={<FAQ />} />
                <Route exact path="/privacy/" element={<Privacy />} />
                <Route exact path="/cookie/" element={<Cookie />} />
                <Route exact path="/impressum/" element={<Impressum />} />
                <Route exact path="/agb/" element={<AGB />} />
                <Route exact path="/password-reset-confirm/:uid/:token" element={<PasswordForm></PasswordForm>} />
                <Route exact path="/CheckMail/:email" element={<CheckMail></CheckMail>} />
                <Route exact path="/VerifiedMail/" element={<VerifiedMail></VerifiedMail>} />
                <Route exact path="/PasswordResetSuccess/" element={<PasswordResetSuccess></PasswordResetSuccess>} />
                <Route exact path="/PasswordSend/" element={<PasswordSend></PasswordSend>} />
                <Route exact path="/MyInquiries/" element={<MyInquiries></MyInquiries>} />
                <Route exact path="/Regeln/" element={<Regeln></Regeln>} />
                <Route
                  path="*"
                  element={<Navigate to="/" replace />}
                />
              </Routes>
            </Router>
            <CookieConsent
              location="bottom"
              buttonText="Zulassen"
              cookieName="michmieten.ch Cookie"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
            >
              Wir und ausgewählte Dritte setzen für technische Zwecke und, mit Ihrer Einwilligung, für „einfache Interaktionen & Funktionalitäten“ und Anmeldung & Registrierung Cookies und ähnliche Technologien ein, so wie in der Cookie-Richtlinie beschrieben. Ablehnung kann entsprechende Funktionen unverfügbar machen.
              Ihre Einwilligung können Sie jederzeit erteilen, verweigern oder widerrufen
              Verwenden Sie den „Zustimmen“-Button um der Verwendung solcher Technologien zuzustimmen.{" "}
            </CookieConsent>
          </div>
        </Provider>
      </HelmetProvider>

    );

  }
}
export default App

