import React from 'react'
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { postreview } from '../../actions/gigs';
import Box from './images/box-empty.svg'
import slash from './images/slash.svg'
import user from './images/user.svg'

function Comment(props) {
    const navigate = useNavigate();


    const [reviewText, setreviewText] = useState([]);


    const handleChange = (e) => {
        setreviewText(e.target.value)
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();


        if (!props.isAuthenticated) {
            navigate("/login")
            return
        }


        if (reviewText.length != 0) {
            console.log("here")
            props.postreview(reviewText, props.id)
        }

    }


    if (props.reviews.length > 0) {

        return (
            < div class="antialiased mx-auto " >
                <div class="space-y-4">
                    <h3 class=" text-left py-8 px-3 text-2xl font-semibold text-gray-900">Dein Kommentar</h3>
                    <form class="shadow-lg" onSubmit={(event) => handleOnSubmit(event)}>
                        <div class="mb-4 w-full bg-gray-50  border border-gray-200 dark:bg-gray-700 dark:border-gray-600">
                            <div class="py-2 px-4 bg-white rounded-t-lg dark:bg-gray-800">
                                <label for="comment" class="sr-only">Your comment</label>
                                <textarea value={reviewText} onChange={handleChange} id="comment" rows="4" class="px-0 w-full text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="Schreibe einen Kommentar..." required></textarea>
                            </div>
                            <div class="flex justify-between items-center py-2 px-3 border-t dark:border-gray-600">
                                <button type="submit" class="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800">
                                    Poste Kommentar
                                </button>
                            </div>
                        </div>
                    </form>
                    <p class="ml-auto text-xs text-gray-500 dark:text-gray-400">Denke daran, dass Beiträge zu diesem Inserat unseren <a href="/Regeln" class="text-blue-600 dark:text-blue-500 hover:underline">Community-Richtlinien</a> entsprechen sollten.</p>

                    <h3 class=" text-left px-3 text-2xl py-4 font-semibold text-gray-900">User-Kommentare</h3>


                    {/* 
    <div class="flex-shrink-0 mr-3">
        <img class="mt-2 rounded-full w-8 h-8 sm:w-10 sm:h-10" src="https://images.unsplash.com/photo-1604426633861-11b2faead63c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80" alt=""/>
    </div>*/}
                    <div class="text-left py-2 sm:px-6 space-y-4 sm:py-4 leading-relaxed ">
                        {props.reviews?.map(
                            content => (
                                <div class="text-left border border-dashed border-gray-900 px-2 py-2 sm:py-4 leading-relaxed rounded-2xl">
                                    <article>
                                        <div class="flex items-center space-x-4">
                                            <div className='pb-4'>
                                                <img class="w-4 h-4  md:w-8 md:h-8" src={user} />
                                            </div>
                                            <div class="space-y-1  font-medium dark:text-white">
                                                <p>{content.user.username}<time datetime="2014-08-16 19:00" class="block text-sm text-gray-500 dark:text-gray-400">{content.created}</time></p>
                                            </div>
                                        </div>
                                        <p class="mb-2 px-12 font-light text-gray-500 dark:text-gray-400">{content.content}</p>
                                        <aside>
                                           
                                        </aside>
                                    </article>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        )

    }

    else {
        return (
            < div class="antialiased mx-auto " >
                <div class="space-y-4">
                    <h3 class="text-left px-3 py-4 text-2xl font-semibold text-gray-900">Dein Kommentar</h3>
                    <form onSubmit={(event) => handleOnSubmit(event)}>
                        <div class="mb-4 w-full bg-gray-50  border border-gray-200 dark:bg-gray-700 dark:border-gray-600">
                            <div class="py-2 px-4 bg-white rounded-t-lg dark:bg-gray-800">
                                <label for="comment" class="sr-only">Your comment</label>
                                <textarea value={reviewText} onChange={handleChange} id="comment" rows="4" class="px-0 w-full text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="Schreibe einen Kommentar..." required></textarea>
                            </div>
                            <div class="flex justify-between items-center py-2 px-3 border-t dark:border-gray-600">
                                <button type="submit" class="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800">
                                    Poste Kommentar
                                </button>
                            </div>
                        </div>
                    </form>
                    <p class="ml-auto text-xs text-gray-500 dark:text-gray-400">Denke daran, dass Beiträge zu diesem Inserat unseren <a href="/Regeln" class="text-blue-600 dark:text-blue-500 hover:underline">Community-Richtlinien</a> entsprechen sollten.</p>
                    <h3 class=" text-left px-3 text-2xl py-4 font-semibold text-gray-900">User-Kommentare</h3>
                    <p className=' py-4'> Noch keine Kommentare vorhanden</p>

                </div>
            </div>
        )
    }
}

Comment.propTypes = {
    isAuthenticated: PropTypes.bool,
    postreview: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { postreview })(Comment)