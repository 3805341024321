import api from "./api";
import TokenService from "./token.service";

class UserService {

  async getUserGigs(){
    const response = await api.get(`/`);
    console.log(response)
    return response
  }

  async getUserProfile(){
    const response = await api.get(`/get_user/`, TokenService.getLocalAccessTokenHeader());
    return response
  }

  async updateUserProfile(){
    const response = await api.get(`/get_user/`, TokenService.getLocalAccessTokenHeader());
    return response
  }

  async updateUserProfile(){
    const response = await api.get(`/get_user/`, TokenService.getLocalAccessTokenHeader());
    return response
  }

  async closeUserProfile(){
    const response = await api.get(`/get_user/`, TokenService.getLocalAccessTokenHeader());
    return response
  }
}

export default new UserService();