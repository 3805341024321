import React from 'react';
import 'antd/dist/antd.css';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import { useStepperContext } from "../stepper.context.component";
import { toast } from 'react-toastify';
import { PropTypes } from 'react'
import { connect } from "react-redux";
import { deleteGigImage } from "../../../actions/gigs";
import gigService from '../../../services/gig.service';


const FileUploaderWrapper = (props) => {
    const { userData, setUserData } = useStepperContext();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };


    return <FileUploader userData={userData} setUserData={setUserData} filelist={props.filelist} />
}



function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


class FileUploader extends React.Component {

    constructor() {
        super();
            this.handleRemove = this.handleRemove.bind(this);
      }

    state = {
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
        fileList: [

        ],
        temp: []
    };

    //https://stackoverflow.com/questions/60522175/wait-for-react-props-received-from-parent-to-update-inside-a-childs-function
    componentDidUpdate(prevProps) {
        if (this.props.filelist !== prevProps.filelist) {

            let arr = []

            for (let i = 0; i < this.props.filelist.length; i++) {
                arr.push({
                    url: this.props.filelist[i].image,
                    id: this.props.filelist[i].id,
                })
            }
            this.setState({fileList: arr})
        }
    }



    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
            console.log(file)
            toast.info("Hauptbild gesetzt", {
                toastId: file.uid,
                position: "bottom-center",
                theme: "colored"
            })
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChange = ({ fileList }) => {
        this.props.setUserData({ ...this.props.userData, fileList: fileList });

        this.setState({ fileList })
    };

    handleRemove(e){
        console.log("CALLED")
        gigService.deleteImage(e.id)
    }

    render() {

        const { previewVisible, previewImage, fileList, previewTitle } = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        return (
            <>
                <Upload
                    beforeUpload={() => false}
                    listType="picture-card"
                    fileList={this.state.fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    multiple={true}
                    onRemove={this.handleRemove}
                >
                    {fileList.length >= 4 ? null : uploadButton}

                </Upload>
                <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </>
        );
    }
}

export default connect() (FileUploaderWrapper);