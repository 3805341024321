import { createContext, useContext, useState } from "react";

const StepperContext = createContext({ userData: "", setUserData: null });

export function UseContextProvider({ children } ) {
  const [userData, setUserData] = useState({
    title: "",
    category: '',
    description: '',
    conditions: '',
    location: '',
    zip: '',
    lat: '',
    lon: '',
    city:'',
    canton: '',
    price_hour: '',
    price_day: '',
    price_week: '',
    rent_duration: '',
    country:'',
  });
 

  return (
    <StepperContext.Provider value={{ userData, setUserData }}>
      {children}
    </StepperContext.Provider>
  );
}

export function useStepperContext() {
  const { userData, setUserData } = useContext(StepperContext);
  
  return { userData, setUserData };
}