import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import OnlyLogo from '../../Images/Logo/Only_Logo.png'
import { useNavigate } from 'react-router-dom';

// REDUX
import { connect } from 'react-redux';
import { resetPassword } from '../../actions/auth';
import setAlert from '../../actions/alert';
import { Footer } from '../Footer/footer.component';

const PasswordReset = ({ isAuthenticated, resetPassword, setAlert }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: ''
    });

    if (isAuthenticated) {
        return navigate(-1)
    }

    const onChange = e => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });

    const { email } = formData;

    const onSubmit = e => {
        e.preventDefault();

        if (!email.trim()) {
            setAlert('Please fill out all fields!');
        } else {
            resetPassword(formData.email);
            setFormData({
                ...formData,
                email: ''
            });
        }
        navigate('/PasswordSend')
    };


    return (
        <div className="min-h-screen text-left flex flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-24 w-auto"
            src={OnlyLogo}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">passwort vergessen</h2>

        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 md:shadow-2xl sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={e => onSubmit(e)} method="POST" >
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id='email'
                    type='text'
                    name='email'
                    value={email}
                    placeholder='Email eingeben'
                    onChange={e => onChange(e)}
                    autoComplete='email'
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>


              <div>
                <button
                  value={1}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Abschicken
                </button>
              </div>
            </form>

            <div className="relative py-8">
                <div className="absolute inset-0 flex items-center">
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Ich erinnere mich an mein Passwort,  
                  <a href='/Login'> Einloggen </a>
                   </span>
                </div>
              </div>

            
          </div>
        </div>

        <Footer></Footer>
      </div>
    )
}

PasswordReset.propTypes = {
    isAuthenticated: PropTypes.bool,
    resetPassword: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { resetPassword, setAlert })(PasswordReset);