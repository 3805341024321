import Product from "./product.component";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';

import React from 'react'

import { connect } from 'react-redux'

//https://stackoverflow.com/questions/69087817/invalid-hook-call-in-class-component-when-i-try-to-use-useparams-to-get-the-para
export const ProductWrapper = ({ isAuthenticated, setAlert, id }) => {
  const params = useParams();
  return (
    <div> <Product params={params} {...{ isAuthenticated, setAlert, id }} />
    </div>

  )
}

ProductWrapper.propTypes = {
  isAuthenticated: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
  id: PropTypes.number
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  username: state.auth.username,
  first_name: state.auth.first_name,
  last_name: state.auth.last_name,
  id: state.auth.id
});

export default connect(mapStateToProps)(ProductWrapper)

