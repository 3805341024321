import React, { useEffect} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { get_latest_gigs } from '../../actions/gigs';
import Slider from "react-slick";
import BlackCard from './card.component';


//https://www.npmjs.com/package/@ant-design/react-slick

function Caroussel(props) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 1,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };

  const showLoading = () => {
    return (
      <div>
          <div class="flex items-center justify-center space-x-2">
            <div class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow inline-block w-12 h-12 bg-current rounded-full opacity-0" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
      </div>
    )
  }

  const showResult = () => {


    if (props.data.gigPayload.data.length === 0) {
      return (
        <div class="min-h-screen max-h-screen  max-w-7xl mx-auto">
          <div class="flex flex-col -space-y-12">
            <h1 class="text-4xl py-4">Fehler keine Resultate</h1>

          </div>
        </div>
      )
    }

    if (props.data.gigPayload.data !== undefined) {
      for (let i = 0; i < props.data.gigPayload.data.length; i++) {
      }

      return (
        <div>
          <Slider {...settings} dots={true} >
          {props.data.gigPayload.data?.map(content => (
            <div>
              <BlackCard content={content} ></BlackCard>
            </div>
          ))}
          </Slider >
        </div>
      )
    }
  }

  useEffect(() => {
    props.get_latest_gigs()
  }, []) // 




  return (
    <div>
      <div class="max-w-7xl mx-auto">
        <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Aktuelle Mietinserate<span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400"></span></h1>
        {props.data.loading ? showLoading() : showResult()}
      </div>
    </div>);
}


Caroussel.propTypes = {
  get_latest_gigs: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  data: state.gig
});

export default connect(mapStateToProps, { get_latest_gigs })(Caroussel);