import React from 'react'

function CategoryCard(props) {

    return (
        <div>
            <a className="relative p-4 w-full bg-white rounded-lg overflow-hidden hover:shadow flex flex-col justify-center  items-center" href={props.category.href}>
                <div className="w-16 md:w-52  grid  bg-gray-100 md:p-12 py-8 px-12 justify-center rounded-lg bg-gradient-to-r from-indigo-200">
                    <props.category.icon className="text-gray-600 justify-self-center h-8 w-8 md:h-12 md:w-12" aria-hidden="true" />
                </div>
                <h2 className="mt-2 text-gray-800 text-base font-semibold line-clamp-1">
                    {props.category.name}
                </h2>
            </a>
        </div>
    )
}

export default CategoryCard