import { useStepperContext } from "../stepper.context.component";
import FileUploaderWrapper from "./fileuploader.component";
import { useForm } from "react-hook-form";
import ReactQuill from 'react-quill';
import { useParams } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import { deleteGigImage } from "../../../actions/gigs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PropTypes from 'prop-types';
import { connect } from 'react-redux'


function Details(props) {
  const { userData, setUserData } = useStepperContext();
  const { register, formState: { errors }, handleSubmit } = useForm();
  const { slug } = useParams()

  function handleRemoveImg(img_id){
    props.deleteGigImage(img_id)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleTitle = (e) => {

    if(e.target.value==''){
      setUserData({...userData, ["title"]: ''})
    }
    else{
      if(e.target.value){
        setUserData({...userData, ["title"]: e.target.value})
      }
    }
  };

  const handleDescription = (e) => {
    setUserData({ ...userData, ["description"]: e });
  }

  const handleConditions = (e) => {
    setUserData({ ...userData, ["conditions"]: e });
  }

  const handleCategories = (e) => {

    setUserData({ ...userData, ["category"]: e.target.value });

  }

  const checkNegativePricePerWeekNumber = (e) => {


    if (e.target.value < 0) {
      e.target.value = 0
    }
    else {
      setUserData({ ...userData, ["price_week"]: e.target.value });
    }

  }

  const checkNegativePricePerDayNumber = (e) => {


    if (e.target.value < 0) {
      e.target.value = 0
    }
    else {
      setUserData({ ...userData, ["price_day"]: e.target.value });
    }

  }

  const checkNegativePricePerHourNumber = (e) => {


    if (e.target.value < 0) {
      e.target.value = 0
    }
    else {
      setUserData({ ...userData, ["price_hour"]: e.target.value });
    }

  }

  const checkNegativeRentDuration = (e) => {

    if (e.target.value < 0) {
      e.target.value = 0
    }
    else {
      setUserData({ ...userData, ["rent_duration"]: e.target.value });
    }

  }



  return (
    <div className="flex flex-col ">
      <section class="bg-white dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-md">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Mietinserate bearbeiten</h2>
          <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl"></p>
          <div action="#" class="space-y-8 text-left">
            <div>
              <label class="block mb-2  font-medium text-gray-900 dark:text-gray-300">Wie lautet der Titel deines Mietinserat
              </label>
              <input
                class="shadow-sm border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                {...register("title", { required: "Erforderlich" })}
                onChange={handleTitle}
                value={userData["title"]}
                name="title"
                placeholder={props.content.title} />
            </div>

            <div>
              <label for="subject" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Welches Kategorie passt dein Inserat am besten
              </label>
              <label for="underline_select" name="category" class="sr-only">Underline select</label>
              <select value={userData["category"] || props.content.category} onChange={handleCategories} id="underline_select" class="block px-2.5 py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-1 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                <option value={"Auswählen"}></option>
                <option value="Audio">Audio</option>
                <option value="Bücher">Bücher</option>
                <option value="Computer">Computer</option>
                <option value="Dienstleistungen">Dienstleistungen</option>
                <option value="Fahrzeuge">Fahrzeuge</option>
                <option value="Handwerk">Handwerk</option>
                <option value="Immobilien">Immobilien</option>
                <option value="Möbel">Möbel</option>
                <option value="Kleidung">Kleidung</option>
                <option value="TV">TV</option>
                <option value="Sonstiges">Sonstiges</option>
                <option value="DM">DM</option>
              </select>
            </div>

            <div>
              <label class="block mb-2  font-medium text-gray-900 dark:text-gray-300">Postleitzahl kann nicht mehr geändert werden
              </label>
              <input
                type="number"
                class="shadow-sm border bg-gray-50 border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                {...register("plz", { required: "Erforderlich" })}
                onChange={handleChange}
                value={userData["zip"]}
                name="zip"
                placeholder={props.content.zip}
                disabled={true} />
            </div>

            <div>
              <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Beschreibe deinen Inserat</label>

              <div class="">
                <ReactQuill name="desc" theme={"snow"} style={{ height: '200px', width: '100%' }} value={userData["description"] || props.content.description} placeholder="Schreibe hier rein..." name="description" onChange={handleDescription} />
              </div>
            </div>

            <div class="py-12">
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Wie sieht des mit der Versicherung aus
              </label>
              <div class="">
                <ReactQuill name="cond" theme={"snow"} style={{ height: '200px', width: '100%' }} value={userData["conditions"] || props.content.conditions} placeholder="Schreibe hier rein..." name="conditions" onChange={handleConditions} />
              </div>
            </div>

            <div class=" justify-center items-center w-full">
              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Lade maximal 4 Bilder hoch</label>
              <label class="block mb-2  text-gray-700 dark:text-gray-200">Das 1.Bild wird als Hauptbild ausgewählt</label>
              <FileUploaderWrapper filelist={props.content.photolist} deleteGigImage={handleRemoveImg}/>

            </div>

            <div>
              <label class="block mb-2  font-medium text-gray-900 dark:text-gray-300">Wieviel kostet es pro Stunde
              </label>
              <input
                {...register("price_hour", { required: "Erforderlich" })}
                onChange={checkNegativePricePerHourNumber}
                value={userData["price_hour"] }
                name="price_hour"
                placeholder={props.content.price_hour}
                type="number"
                class="shadow-sm  border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" required />
            </div>

            <div>
              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Wieviel kostet es pro Tag
              </label>
              <input {...register("price_day", { required: "Erforderlich" })}
                onChange={checkNegativePricePerDayNumber}
                value={userData["price_day"] }
                placeholder={props.content.price_day}
                name="price_day"
                type="number"
                class="shadow-sm  border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"required />
            </div>

            <div>
              <label class="block mb-2 font-medium text-gray-900 dark:text-gray-300">Wieviel kostet es pro Woche
              </label>
              <input  {...register("price_week", { required: "Erforderlich" })}
                onChange={checkNegativePricePerWeekNumber}
                value={userData["price_week"] }
                name="price_week"
                type="number"
                placeholder={props.content.price_week}
                class="shadow-sm  border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"  required />
            </div>

            <div class="">
              <label for="email" class="block mb-2 font-medium text-gray-900 dark:text-gray-300">Was ist die Mindestdauer für die Miete
              </label>
              <input  {...register("rent_duration", { required: "Erforderlich" })}
                onChange={checkNegativeRentDuration}
                value={userData["rent_duration"] }
                name="rent_duration"
                type="number"
                placeholder={props.content.rent_duration}
                class="shadow-sm border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"  required />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

Details.propTypes = {
  deleteGigImage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps,{ deleteGigImage }) (Details)