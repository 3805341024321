import React from 'react'
import ThumbnailCloudinary from '../../common/thumbnail.cloudinary.component'
import {
    LocationMarkerIcon,
    CashIcon,
    StarIcon
} from '@heroicons/react/solid'
import { useNavigate } from 'react-router-dom';
import MyinquiriesSendmailComponent from './myinquiries.sendmail.component';
import Profile from './images/profile.svg'

function MyInquiriesCard(props) {
    const navigate = useNavigate()
    return (
        <div>
            <div class="flex  flex-col space-y-4 p-4">
                {props.results?.map(content => (
                    <div class="shadow-lg border"  >
                        {/* Card Picture*/}

                        <div class="md:h-42 md:flex flex-row gap-5 ">

                            <div class=" md:hidden flex justify-center">
                                <ThumbnailCloudinary class="" ImagePath={content.gig.images} width={350} height={200} />
                            </div>

                            <div class="p-4 hidden sm:block">
                                <ThumbnailCloudinary class="" ImagePath={content.gig.images} width={200} height={250} />
                            </div>

                            {/* Clickable Object*/}
                            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0 p-4 ">
                                <div class="" onClick={() => navigate("../Product/" + content.gig.id)}>
                                    <a >
                                        <h1 class="text-black text-2xl title-font font-bold mb-2" >
                                            {content.gig.title}
                                        </h1>
                                    </a>
                                </div>
                                {/* Text*/}
                                <div class="md:flex font-bold text-gray-800">
                                    <div class="w-full md:w-1/2 flex space-x-3">
                                        <div class="w-1/2">
                                            <h2 class="text-gray-500">Start Datum</h2>
                                            <p >{content.start_date}</p>
                                        </div>
                                        <div class="w-1/2">
                                            <h2 class="text-gray-500">End Datum</h2>
                                            <p>{content.end_date}</p>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/2 flex space-x-3">
                                        <div class="w-1/2">
                                            <h2 class="text-gray-500">Bemerkung</h2>
                                            <p>{content.remark}</p>
                                        </div>
                                    </div>
                                </div>

                                {  /* Mehr info*/}
                            </div>

                        </div>
                        <div class="flex bg-gray-50 ">
                            {  /* Picture User*/}
                            <div class="text-center w-1/2 p-2 hover:bg-gray-100 cursor-pointer ">

                                <div class="md:flex items-center  ">
                                    <div class="flex-shrink-0 flex px-4 justify-center">
                                        <img class="w-8 h-8 rounded-full"
                                            src={Profile} alt="Neil image" />
                                    </div>
                                    <div class="flex-1 min-w-0">
                                        <p class="text-sm font-medium text-gray-900 truncate dark:text-white md:mt-4">
                                            {content.gig.username}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="border"></div>
                            {  /*  User location*/}

                            <div class=" md:flex text-center w-1/2 p-2 justify-center items-center hover:bg-gray-100 cursor-pointer">
                                <div className=' flex justify-center  '>
                                    <LocationMarkerIcon class="h-8 w-8 text-red-300" />
                                </div>
                                <div class="flex-1 min-w-0">
                                    {content.gig.city.length > 12 &&
                                        <p class=" md:mt-4 truncate ...">{content.gig.zip} {content.gig.city}...</p>}
                                    {content.gig.city.length <= 12 &&
                                        <p class="md:mt-4">{content.gig.zip} {content.gig.city}</p>}

                                </div>
                            </div>
                            <div class="border "></div>
                            {  /*  User Price*/}

                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MyInquiriesCard