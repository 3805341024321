import React from 'react'
import { connect } from 'react-redux';
import ThumbnailCloudinary from '../../common/thumbnail.cloudinary.component'
import { useNavigate } from 'react-router-dom';
import LikeButtonComponent from './like.button.component';
import DislikeButtonComponent from './dislike.button.component';
import ActivateButton from './activate.deactivate.button.component';
import DeleteButton from './delete.button.component';
import Profile from './images/profile.svg'

import {
    LocationMarkerIcon,
    CashIcon,
    StarIcon
} from '@heroicons/react/solid'


function Card(props) {
    const navigate = useNavigate()
    return (
        <div>
            {/* Cards*/}
            <div class="flex  flex-col space-y-4 p-4">
                {props.results?.map(content => (
                    <div class="shadow-lg border"  >
                        {/* Card Picture*/}

                        <div class="md:h-42 md:flex flex-row gap-5 ">

                            <div class=" md:hidden flex justify-center bg-gray-100" >
                                <ThumbnailCloudinary class="" ImagePath={content.images} width={350} height={200} />
                            </div>

                            <div class="p-4 hidden sm:block">
                                <ThumbnailCloudinary class="" ImagePath={content.images} width={200} height={250} />
                            </div>

                            {/* Clickable Object*/}
                            <div class="flex flex-1 flex-col  p-4 ">
                                <div onClick={() => navigate("../Product/" + content.id)}>
                                    <a >
                                        <h1 class="text-lg md:text-md break-all text-left font-semibold  text-gray-600" >
                                            {content.title}
                                        </h1>
                                    </a>
                                </div>
                                {/* Description*/}
                                <p class="line-clamp-3 text-gray-500 text-xs break-normal md:break-all text-left md:text-md sm:text-base overflow-hidden ">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: content.description }}                                                >
                                    </div>                                            </p>

                                {  /* Mehr info*/}
                            </div>

                            {/* Like*/}
                            <div class=' md:text-lg py-2 flex md:flex-col justify-around '>
                                
                                <div class="px-4">
                                    <LikeButtonComponent noLikes={content.likes.length} contentID={content.id} likes={content.likes} userID={props.userID}></LikeButtonComponent>
                                    <p class="text-sm">Likes</p>
                                </div>
                                <div class="px-4">
                                    <DislikeButtonComponent noDislikes={content.dislikes.length} contentID={content.id} dislikes={content.dislikes} userID={props.userID}></DislikeButtonComponent>
                                    <p class="text-sm">Nopes</p>
                                </div>
                                
                            </div>
                        </div>
                        <div class="flex bg-gray-50 ">
                            {  /* Picture User*/}
                            <div class="text-center w-1/2 p-2 hover:bg-gray-100 cursor-pointer ">

                                <div class="md:flex items-center  ">
                                    <div class="flex-shrink-0 flex justify-center">
                                        <img class="w-8 h-8 rounded-full"
                                            src={Profile} alt="Neil image" />
                                    </div>
                                    <div class="flex-1 min-w-0">
                                        <p class="text-sm font-medium text-gray-900 truncate dark:text-white md:mt-4">
                                            {content.username}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="border"></div>
                            {  /*  User location*/}
                            <div class=" md:flex text-center w-1/2 p-2 justify-center items-center hover:bg-gray-100 cursor-pointer">
                                <div className=' flex justify-center  '>
                                    <LocationMarkerIcon class="h-8 w-8 text-red-300" />
                                </div>
                                <div class="flex-1 min-w-0">
                                    {content.city.length > 12 &&
                                        <p class=" md:mt-4 truncate ...">{content.zip} {content.city}...</p>}
                                    {content.city.length <= 12 &&
                                        <p class="md:mt-4">{content.zip} {content.city}</p>}

                                </div>
                            </div>
                            <div class="border "></div>
                            {  /*  User Price*/}
                            <div class=" md:flex text-center w-1/2 p-2 justify-center items-center hover:bg-gray-100 cursor-pointer">
                                <div class="flex justify-center">
                                    <CashIcon class="h-8 w-8 mx-3 text-green-300" />
                                </div>
                                <div>
                                    <span class="text-sm font-semibold">ab</span>&nbsp; <span class="font-bold text-xl">{content.price_hour}</span>&nbsp; <span class="text-sm font-semibold">CHF</span>

                                </div>
                            </div>
                        </div>


                        <div class="flex flex-wrap h-12 ">


                            <button
                                onClick={() => navigate("../Edit/" + content.id)}
                                class=" grow py-2 bg-blue-500 outline-none text-white shadow-lg md:text-lg active:shadow-none active:scale-95 hover:bg-blue-600 focus:bg-indigo-600 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 disabled:bg-gray-400/80 disabled:shadow-none disabled:cursor-not-allowed transition-colors duration-200">

                                <div class="flex justify-center space-x-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                                    </svg>
                                    <span>Bearbeiten</span>
                                </div>

                            </button>
                            <ActivateButton status={content.status} id={content.id}></ActivateButton>

                            <DeleteButton id={content.id} ></DeleteButton>

                        </div>

                    </div>
                ))}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    username: state.auth.username,
    first_name: state.auth.first_name,
    last_name: state.auth.last_name,
    userID: state.auth.id
  });

export default connect(mapStateToProps) (Card)