import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Searchbar() {

    const navigate = useNavigate();
    const [searchKeyword, setSearchKeyword] = useState([])

    // This function will be called whenever the text input changes
    const searchHandler = (event) => {
        let search;
        if (event.target.value) {
            search = {
                search: event.target.value
            }

        } else {
            search = undefined;
        }
        setSearchKeyword(search)
    }

    const OnSubmit = (e) => {
        if (!searchKeyword.search.trim()) {
            console.log("Keyword missing")
        } else {
            console.log("new search")
            navigate("/list/search=" + searchKeyword.search)
        }
    };


    return (
        <div class='max-w-7xl py-12 mx-auto space-y-12'>
            <form id='search_form2'
                onSubmit={e => OnSubmit(e)} class="px-4">
                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Suchen</label>
                <div class="relative ">
                    <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </div>
                    <input onChange={searchHandler} type="search" id="default-search" class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-200 shadow-2xl focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Suche Lieferwagen, Möbel..." required />
                    <button onClick={e => OnSubmit(e)} type="submit" class=" absolute right-2.5 bottom-2.5 shadow-xl bg-amber-200 hover:bg-amber-300 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Suchen</button>
                </div>
            </form>
        </div>
    )
}

export default Searchbar