import {
  ALLGIGS_LOADING,
  ALLGIGS_LOADED,
  ALLGIGS_ERROR,
  CREATE_GIG,
  UPDATE_GIG,
  GIG_ERROR,
  GIG_LIKE,
  GIG_DISLIKE,
  GIG_DELETE,
  GIG_ACTIVATE,
  GIG_DEACTIVATE,

} from './types';

import GigService from '../services/gig.service';
import setAlert from './alert';
import { toast } from 'react-toastify';



export const loadGigs = (data) => (dispatch) => {

  return GigService.getPageSearch(data).then(
    (response) => {
      dispatch({
        type: ALLGIGS_LOADING,
        payload: { user: response },
      });

      // DISPATCH LOAD USER
      dispatch({ type: ALLGIGS_LOADED, payload: response });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ALLGIGS_ERROR,
      });

      dispatch(setAlert(error.response.data, 400, 'error'));

      return Promise.reject();
    }
  );
};

export const getFavorites = () => (dispatch) => {

  return GigService.getFavorites().then(
    (response) => {

      
      dispatch({
        type: ALLGIGS_LOADING,
        payload: { user: response },
      });

      // DISPATCH LOAD USER
      dispatch({ type: ALLGIGS_LOADED, payload: response });

      return Promise.resolve();
    },
    (error) => {

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ALLGIGS_ERROR,
      });
      dispatch(setAlert(error.response.data, 400, 'error'));

      return Promise.reject();
    }
  );
};


export const newCreateGig = (data) => (dispatch) => {
  dispatch(setAlert("Lade...", 200, 'loading'));

  const new_data = data //Weird fix as username contains both username and password
  return GigService.createNewGig(new_data).then(
    (data) => {
      
      toast.dismiss()
      dispatch({
        type: CREATE_GIG,
        payload: { user: data },
      });

      // DISPATCH SET ALERT
      return Promise.resolve();
    },
    (error) => {
      
      toast.dismiss()

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch(setAlert('Server fehler, Bitte versuche es später wieder\n' + error.toString(), 200, 'error'));

      dispatch({
        type: GIG_ERROR,
      });


      return Promise.reject();
    }
  );
};

export const createAlert = (message) => (dispatch) => {

  const new_data = message //Weird fix as username contains both username and password
  return GigService.createAlert(message).then(
    (data) => {
      dispatch(setAlert(message, 200, 'success'));
      return Promise.resolve();
    },
    (error) => {
      
      toast.dismiss()

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch(setAlert('Server fehler, Bitte versuche es später wieder\n' + error.toString(), 200, 'error'));

      dispatch({
        type: GIG_ERROR,
      });


      return Promise.reject();
    }
  );
};


export const editGig = (data, id) => (dispatch) => {

  const new_data = data //Weird fix as username contains both username and password
  return GigService.editGig(new_data, id).then(
    (data) => {
      

      dispatch({
        type: UPDATE_GIG,
        payload: { user: data },
      });

      // DISPATCH SET ALERT
      dispatch(setAlert('Inserat wurde erfolgreich aktualisiert', 200, 'success'));

      return Promise.resolve();
    },
    (error) => {
      

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });
      return Promise.reject();
    }
  );
};

export const searchGig = (data) => (dispatch) => {
  const new_data = data //Weird fix as username contains both username and password
  return GigService.searchGig(new_data).then(
    (data) => {
      
      dispatch({
        type: ALLGIGS_LOADING,
        payload: { user: data },

      });

      // DISPATCH SET ALERT
      return Promise.resolve();
    },
    (error) => {
     


      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });

      dispatch(setAlert('Server fehler, Bitte versuche es später wieder\n' + error.response.data, 200, 'error'));


      return Promise.reject();
    }
  );
};

export const nextPage = (data) => (dispatch) => {

  const new_data = data //Weird fix as username contains both username and password


  return GigService.getPageSearch(new_data).then(
    
    (data) => {
      

      dispatch({
        type: ALLGIGS_LOADING,
        payload: { user: data },
      });

      // DISPATCH SET ALERT
      dispatch(setAlert('Found few', 200, 'success'));

      return Promise.resolve();
    },
    (error) => {
      

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });


      return Promise.reject();
    }
  );
};

export const getPageSearch = (data) => (dispatch) => {

  const new_data = data //Weird fix as username contains both username and password
  console.log(data)
  new_data = new_data.substr(new_data.lastIndexOf("?") + 1)

  return GigService.getPageSearch(new_data).then(
    (data) => {
    
      dispatch({
        type: ALLGIGS_LOADING,
        payload: { user: data },
      });

      // DISPATCH SET ALERT
      dispatch(setAlert('Found few', 200, 'success'));

      return Promise.resolve();
    },
    (error) => {
      

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });


      return Promise.reject();
    }
  );
};


export const prevPage = (data) => (dispatch) => {

  const new_data = data //Weird fix as username contains both username and password
  console.log(data)
  return GigService.getPrevPagePublicContent(new_data).then(
    (data) => {
      

      dispatch({
        type: ALLGIGS_LOADING,
        payload: { user: data },
      });

      // DISPATCH SET ALERT
      dispatch(setAlert('Found few', 200, 'success'));

      return Promise.resolve();
    },
    (error) => {
      

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });


      return Promise.reject();
    }
  );
};

export const likeGig = (data) => (dispatch) => {
  const new_data = data //Weird fix as username contains both username and password
  console.log("Liked")
  return GigService.like(new_data).then(
    (data) => {
      

      // DISPATCH SET ALERT
      dispatch({ type: GIG_LIKE});
      dispatch(setAlert(data.data, 200, 'success'));

      return Promise.resolve();
    },
    (error) => {
      

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });
      return Promise.reject();
    }
  );
};

export const dislikeGig = (data) => (dispatch) => {
  const new_data = data //Weird fix as username contains both username and password

  return GigService.dislike(new_data).then(
    (data) => {
      

      // DISPATCH SET ALERT
      dispatch({ type: GIG_DISLIKE});
      dispatch(setAlert(data.data, 200, 'success'));

      return Promise.resolve();
    },
    (error) => {
      

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });
      return Promise.reject();
    }
  );
};

export const add_favourites = (data) => (dispatch) => {
  const new_data = data //Weird fix as username contains both username and password

  return GigService.add_favourites(new_data).then(
    (data) => {
      

      // DISPATCH SET ALERT
      dispatch({ type: GIG_DISLIKE});
      dispatch(setAlert(data.data, 200, 'success'));

      return Promise.resolve();
    },
    (error) => {
     

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });
      return Promise.reject();
    }
  );
};

export const deleteGig = (data) => (dispatch) => {

  const new_data = data //Weird fix as username contains both username and password

  return GigService.deleteGig(new_data).then(
    (data) => {

      // DISPATCH SET ALERT
      dispatch({ type: GIG_DELETE});
      dispatch(setAlert(data.data, 200, 'info'));
      window.setTimeout(function () { window.location.reload() }, 5000)

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });
      return Promise.reject();
    }
  );
};

export const postreview = (data, gig_id) => (dispatch) => {

  const new_data = data //Weird fix as username contains both username and password
  console.log("Posted review")
  return GigService.postreview(gig_id, new_data).then(
    (data) => {
      

      // DISPATCH SET ALERT
      dispatch(setAlert(data.data, 200, 'success'));

      return Promise.resolve();
    },
    (error) => {
     

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });
      return Promise.reject();
    }
  );
};

export const inquire = (start_date, end_date, remark, gig_id) => (dispatch) => {
  console.log("Inquiry send")
  dispatch(setAlert("Lade...", 200, 'loading'));

  return GigService.inquire(start_date, end_date, gig_id, remark).then(
    (data) => {
      toast.dismiss()

      // DISPATCH SET ALERT
      dispatch(setAlert(data.data, 200, 'success'));

      return Promise.resolve();
    },
    (error) => {
      toast.dismiss()

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });
      return Promise.reject();
    }
  );
};

export const get_latest_gigs = () => (dispatch) => {
  console.log("Get_latest_gigs")
  return GigService.getLatestContent().then(
    (response) => {
      

      // DISPATCH SET ALERT
      dispatch({
        type: ALLGIGS_LOADING,
        payload: { user: response },
      });

      // DISPATCH LOAD USER
      dispatch({ type: ALLGIGS_LOADED, payload: response });


      return Promise.resolve();
    },
    (error) => {
     

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });
      return Promise.reject();
    }
  );
};

export const activateGig = (data, id) => (dispatch) => {
  const new_data = data //Weird fix as username contains both username and password
  console.log("Gig activated")
  return GigService.editGig(new_data, id).then(
    (data) => {
      

      // DISPATCH SET ALERT
      if (data.status) {
        dispatch(setAlert("Inserat aktiviert", 200, 'info'));
        dispatch({type: GIG_ACTIVATE,});
      }
      if (!data.status) {
        dispatch(setAlert("Inserat deaktiviert", 200, 'info'));
        dispatch({type: GIG_DEACTIVATE,});
      }
      if (data)
        window.setTimeout(function () { window.location.reload() }, 5000)


      return Promise.resolve();
    },
    (error) => {

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });
      return Promise.reject();
    }
  );
};

export const deleteGigImage = (img_id) => (dispatch) => {
  const new_data = img_id //Weird fix as username contains both username and password
  console.log("Gig image deleted")
  return GigService.deleteImage(img_id).then(
    (data) => {

      // DISPATCH SET ALERT
      dispatch(setAlert("Bild gelöscht", 200, 'info'));
      return Promise.resolve();
    },
    (error) => {

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });

      dispatch(setAlert("Bild konnte nicht geläscht werden", 200, 'error'));

      return Promise.reject();
    }
  );
};

export const deactivateGig = (data, id) => (dispatch) => {
  const new_data = data //Weird fix as username contains both username and password
  console.log("Gig deactivated")
  return GigService.editGig(new_data, id).then(
    (data) => {

      // DISPATCH SET ALERT
      dispatch(setAlert("Inserat status update: Aktiviert", 200, 'info'));
      window.setTimeout(function () { window.location.reload() }, 5000)

      return Promise.resolve();
    },
    (error) => {

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GIG_ERROR,
      });
      return Promise.reject();
    }
  );
};

export const createValidationAlert = (data) => (dispatch) => {
  dispatch(setAlert(data, 200, 'error'));
};