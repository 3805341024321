import axios from "axios";
import TokenService from "./token.service";
import { useNavigate } from 'react-router-dom';


const instance = axios.create({
 // baseURL: "http://localhost:8000/api/",
 baseURL: "https://mietplace-django.onrender.com/api/",
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      //config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


//https://stackoverflow.com/questions/51646853/automating-access-token-refreshing-via-interceptors-in-axios
  const interceptor = instance.interceptors.response.use(
    response => response,
    error => {
      // Reject promise if usual error
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }

      /* 
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      instance.interceptors.response.eject(interceptor);

      var bodyFormData = new FormData();
      bodyFormData.append('refresh', TokenService.getLocalRefreshToken());

      return instance.post('auth/token/refresh/', bodyFormData).then(response => {
        const accessToken = response.data.access;
        TokenService.updateLocalAccessToken(accessToken);

        error.response.config.headers['Authorization'] = 'JWT ' + response.data.access;
        return instance(error.response.config);
      }).catch(error => {
        TokenService.removeLocalAccessToken();
        TokenService.removeLocalRefreshToken();
        useNavigate('/')
        return Promise.reject(error);
      }).finally(interceptor);
    }
  );


export default instance;