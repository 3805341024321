import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import OnlyLogo from '../../Images/Logo/Only_Logo.png'
import { useNavigate } from 'react-router-dom';

// REDUX
import { connect } from 'react-redux';
import { registerUser } from '../../actions/auth';
import setAlert from '../../actions/alert';
import { Footer } from '../Footer/footer.component';

const Register = ({ isAuthenticated, registerUser, setAlert }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: '',
    password: '',
    password2: '',
    email: '',
  });


  if(isAuthenticated){
    return(
      <div>
          <Navigate to="/" replace={true} />
      </div>
    )
  }



  const onChange = e => setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });

  const { username, password, email, password2 } = formData;

  const onSubmit = e => {
    e.preventDefault();

    if (!username.trim() || !password.trim()) {
      setAlert('Please fill out all fields!', 400, 'danger');
    } else {
      registerUser(formData);
      setFormData({
        ...formData,
        username: '',
        password: '',
        password2: '',
        email: '',
      });
    }
    navigate("/checkmail/"+ email)
  };

  return (
    <div>
      <div className="min-h-screen text-left flex flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-24 w-auto"
            src={OnlyLogo}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">gratis registrieren</h2>

        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 md:shadow-2xl sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={e => onSubmit(e)} method="POST" >
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id='email'
                    type='text'
                    name='email'
                    value={email}
                    placeholder='Email eingeben'
                    onChange={e => onChange(e)}
                    autoComplete='email'
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Benutzername
                </label>
                <div className="mt-1">
                  <input
                    id='username'
                    type='username'
                    name='username'
                    value={username}
                    placeholder='Benutzername eingeben'
                    onChange={e => onChange(e)}
                    autoComplete='username'
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Passwort
                </label>
                <div className="mt-1">
                  <input
                    id='password'
                    type='password'
                    name='password'
                    value={password}
                    placeholder='Passwort eingeben'
                    onChange={e => onChange(e)}
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Passwort bestägigen
                </label>
                <div className="mt-1">
                  <input
                    id='password2'
                    type='password'
                    name='password2'
                    value={password2}
                    placeholder='Passwort eingeben'
                    onChange={e => onChange(e)}
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Indem ich fortfahre, akzeptiere ich die 
                  <a href='/agb'> Allgemeinen Geschäftsbedingungen </a>
                   (inkl. <a href='/privacy'> Datenschutzerklärung</a>) von mietplace.ch.</span>
                </div>
              </div>

            </div>

              <div>
                <button
                  value={1}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Registrieren
                </button>
              </div>
            </form>

            <div className="relative py-8">
                <div className="absolute inset-0 flex items-center">
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Ich besitze ein Konto,  
                  <a href='/Login'> Einloggen </a>
                   </span>
                </div>
              </div>
          </div>
        </div>
      </div>


<Footer></Footer>
      </div>
  )
}

Register.propTypes = {
  isAuthenticated: PropTypes.bool,
  registerUser: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { registerUser, setAlert })(Register);