import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SEO from "../SEO/seo.component";

function Searchbar() {

    const navigate = useNavigate();
    const [searchKeyword, setSearchKeyword] = useState([])

    // This function will be called whenever the text input changes
    const searchHandler = (event) => {
        let search;
        if (event.target.value) {
            search = {
                search: event.target.value
            }

        } else {
            search = undefined;
        }
        setSearchKeyword(search)
    }

    const OnSubmit = (e) => {
        if (!searchKeyword.search.trim()) {
            console.log("Keyword missing")
        } else {
            console.log("new search")
            navigate("/list/search=" + searchKeyword.search)
        }
    };


    return (

        <div>
            <SEO title= {searchKeyword.search}
                description='Jetzt mieten'
                name='mietplace.ch' />

            <div className='max-w-7xl  mx-auto space-y-8 py-8' >


                <p className="md:text-left text-xl font-adineue px-8">Was möchtest du mieten ?</p>

                <form id='search_form2'
                    onSubmit={e => OnSubmit(e)} className="px-4 ">
                    <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Suchen</label>
                    <div className="relative ">
                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                        </div>
                        <input onChange={searchHandler} type="search" id="default-search" className="block p-4 pl-10 w-full border-gray-200 text-sm text-gray-900 bg-gray-50 rounded-lg shadow-2xl  dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white  " placeholder="Suche Lieferwagen, Möbel..." required />
                        <button onClick={e => OnSubmit(e)} type="submit" className="absolute bg-amber-200 shadow-lg focus:bg-amber-300 hover:bg-amber-100 rounded-xl right-2.5 bottom-2.5  text-sm px-4 py-2 0">Suchen</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Searchbar