import React from 'react'
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dislikeGig } from '../../actions/gigs';

function findById(source, id) {
  for (var i = 0; i < source.length; i++) {

    if (source[i].user === id) {
      return true;
    }
  }
  return false;
}


function DislikeButton(props) {
  const navigate = useNavigate();





  const OnDislikeButton = (e) => {


    if (!props.isAuthenticated) {
      navigate("/login")
      return
    }

    props.dislikeGig(props.contentID)

  }



  if (findById(props.dislikes, props.userID)) {
    return (
      <div className="">
        <button onClick={() => OnDislikeButton()} className="relative">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="w-8 h-8 text-indigo-500 fill-transparent fill-indigo-500">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
          </svg>

          <span
            className="absolute top-0 right-0 px-2 py-1 translate-x-1/2  border border-white rounded-full text-xs text-gray-900 bg-amber-200 font-bold">{props.noDislikes}</span>
        </button>

      </div>
    )
  }

  return (
    <div className="">
      <button onClick={() => OnDislikeButton()} className="relative">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="w-8 h-8 text-indigo-500 fill-transparent hover:text-indigo-500 hover:fill-indigo-500">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
        </svg>

        <span
          className="absolute top-0 right-0 px-2 py-1 translate-x-1/2  border border-white rounded-full text-xs text-gray-900 bg-amber-200 font-bold">{props.noDislikes}</span>
      </button>
    </div>
  )
}

DislikeButton.propTypes = {
  isAuthenticated: PropTypes.bool,
  dislikeGig: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { dislikeGig })(DislikeButton)