class TokenService {
    getLocalRefreshToken() {
        const localRefreshToken = JSON.parse(localStorage.getItem("refresh_token"));
        return localRefreshToken;
    }

    getLocalAccessToken() {
        const LocalAccessToken = JSON.parse(localStorage.getItem("access_token"));
        return LocalAccessToken;
    }

    updateLocalAccessToken(token) {
        let LocalAccessToken = JSON.parse(localStorage.getItem("access_token"));
        LocalAccessToken = token;
        localStorage.setItem("access_token", JSON.stringify(LocalAccessToken));
    }

    getUser() {
        return JSON.parse(localStorage.getItem("user"));
    }

    setLocalAccessToken(token) {
        localStorage.setItem("access_token", JSON.stringify(token));
    }


    setLocalRefreshToken(token) {
        localStorage.setItem("refresh_token", JSON.stringify(token));
    }


    removeLocalAccessToken() {
        localStorage.removeItem("access_token");
    }

    removeLocalRefreshToken() {
        localStorage.removeItem("refresh_token");
    }

    getLocalAccessTokenHeader() {
        let accessToken = localStorage.getItem('access_token')
        accessToken = 'Bearer ' + accessToken.replace(/['"]+/g, '')

        const config = {
            headers: {
                'Authorization': accessToken
            }
        };
        return config
    }

    getLocalRefreshTokenHeader() {
        let refreshToken = localStorage.getItem('refresh_token')
        refreshToken = refreshToken.replace(/['"]+/g, '')

        const config = {
            headers: {
                'refreshToken': refreshToken
            }
        };
        return config
    }

}

export default new TokenService();