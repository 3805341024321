import React from 'react'
import { useGoogleLogin } from '@react-oauth/google';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { googleLogin } from '../../actions/auth';


function GoogleButton({ googleLogin }) {

    const login = useGoogleLogin({
        onSuccess: codeResponse => googleLogin(codeResponse.access_token),
        flow: 'implicit',
    });

    return (
        <a onClick={login} class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:bg-red-500">
            <span class="sr-only">Sign in with Google</span>
            <svg width='20' height='20' class="text-red-500" viewBox='0 0 24 24'>
            <path fill='currentColor'
              d='M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z' />
          </svg>

        </a>
    )
}

GoogleButton.propTypes = {
    googleLogin: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { googleLogin })(GoogleButton);