import React from 'react'
import LikeButtonComponent from './like.button.component';
import DislikeButtonComponent from './dislike.button.component';
import { connect } from 'react-redux';

import ThumbnailCloudinary from '../../common/thumbnail.cloudinary.component'
import {
    LocationMarkerIcon,
    CashIcon,
    StarIcon
} from '@heroicons/react/solid'
import { useNavigate } from 'react-router-dom';
import FavoriteButtonComponent from './favorite.button.component';
import user from './images/user.svg'


function FavoritesCard(props) {
    const navigate = useNavigate()
    return (
        <div>
            <div class="flex  flex-col space-y-4 p-4">
                {props.results?.map(content => (
                    <div class="shadow-lg border"  >
                        {/* Card Picture*/}

                        <div class="md:h-42 md:flex flex-row gap-5 ">

                            <div class="md:hidden flex justify-center">
                                <ThumbnailCloudinary class="" ImagePath={content.gig.images} width={350} height={200} />
                            </div>

                            <div class="p-4 hidden sm:block">
                                <ThumbnailCloudinary class="" ImagePath={content.gig.images} width={200} height={250} />
                            </div>

                            {/* Clickable Object*/}
                            <div class="flex flex-1 flex-col  p-4 ">
                                <div onClick={() => navigate("../Product/" + content.gig.id)}>
                                    <a >
                                        <h1 class="text-lg md:text-md break-all text-left font-semibold  text-gray-600" >
                                            {content.gig.title}
                                        </h1>
                                    </a>
                                </div>
                                {/* Description*/}
                                <p class="line-clamp-3 text-gray-500 text-xs break-normal md:break-all text-left md:text-md sm:text-base overflow-hidden ">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: content.gig.description }}                                                >
                                    </div>                                            </p>

                                {  /* Mehr info*/}
                            </div>

                            {/* Buttons*/}
                            <div class=' md:text-lg py-2 flex md:flex-col justify-around '>
                                <div class="px-4">
                                   <FavoriteButtonComponent contentID={content.gig.id}></FavoriteButtonComponent>
                                    <p class="text-sm">Gemerkt</p>
                                </div>
                                <div class="px-4">
                                    <LikeButtonComponent noLikes={content.gig.likes.length} contentID={content.gig.id} likes={content.gig.likes} userID={content.user}></LikeButtonComponent>
                                    <p class="text-sm">Likes</p>
                                </div>
                                <div class="px-4">
                                    <DislikeButtonComponent noDislikes={content.gig.dislikes.length} contentID={content.gig.id} dislikes={content.gig.dislikes} userID={content.user}></DislikeButtonComponent>
                                    <p class="text-sm">Nopes</p>
                                </div>
                            </div>
                        </div>
                        <div class="flex bg-gray-50 ">
                            {  /* Picture User*/}
                            <div class="text-center w-1/2 p-2 hover:bg-gray-100 cursor-pointer ">

                                <div class="md:flex items-center  ">
                                    <div class="flex-shrink-0 px-2 flex justify-center">
                                        <img class="w-8 h-8 rounded-full"
                                            src={user} alt="Neil image" />
                                    </div>
                                    <div class="flex-1 min-w-0">
                                        <p class="text-sm font-medium text-gray-900 truncate dark:text-white md:mt-4">
                                            {content.gig.username}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="border"></div>
                            {  /*  User location*/}

                            <div class=" md:flex text-center w-1/2 p-2 justify-center items-center hover:bg-gray-100 cursor-pointer">
                                <div className=' flex justify-center  '>
                                    <LocationMarkerIcon class="h-8 w-8 text-red-300" />
                                </div>
                                <div class="flex-1 min-w-0">
                                    {content.gig.city.length > 12 &&
                                        <p class=" md:mt-4 truncate ...">{content.gig.zip} {content.gig.city}...</p>}
                                    {content.gig.city.length <= 12 &&
                                        <p class="md:mt-4">{content.gig.zip} {content.gig.city}</p>}

                                </div>
                            </div>
                            <div class="border "></div>
                            {  /*  User Price*/}
                            <div class=" md:flex text-center w-1/2 p-2 justify-center items-center hover:bg-gray-100 cursor-pointer">
                                <div class="flex justify-center">
                                    <CashIcon class="h-8 w-8 mx-3 text-green-300" />
                                </div>
                                <div>
                                    <span class="text-sm font-semibold">ab</span>&nbsp; <span class="font-bold text-xl">{content.gig.price_hour}</span>&nbsp; <span class="text-sm font-semibold">CHF</span>

                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    username: state.auth.username,
    first_name: state.auth.first_name,
    last_name: state.auth.last_name,
    userID: state.auth.id
  });

export default connect(mapStateToProps) (FavoritesCard)