import React from 'react'
import Edit from './edit.component'
import Navbar from "../Nav-Bar/nav-bar.component";
import { UseContextProvider } from "./stepper.context.component";
import { useParams } from "react-router-dom";
import GigService from '../../services/gig.service';
import { useState, useEffect } from 'react'

export default function EditContext() {

    const [content, setContent] = useState([])
    const { slug } = useParams()

    useEffect(() => {

        let slugdata = { slug };
        let id = slugdata.slug
        GigService.getGig(id).then(
            async response => {
                setContent({
                    title: response.data.title,
                    category: response.data.category,
                    description: response.data.description,
                    conditions: response.data.conditions,
                    photolist: response.data.images,
                    location: response.data.location,
                    zip: response.data.zip,
                    lat: response.data.lat,
                    lon: response.data.lon,
                    canton: response.data.canton,
                    city: response.data.city,
                    price_hour:response.data.price_hour,
                    price_day: response.data.price_day,
                    price_week: response.data.price_week,
                    rent_duration: response.data.rent_duration,
                });
            },
        )

            ;
        //ATTENTION THIS IS ASYNC METHOD CONSOLE WILL NOT PRINT THIS OUT;
    }, []);

    return (
        <div>
            <Navbar/>
            <UseContextProvider  >
                <Edit content={content} />
            </UseContextProvider>
        </div>

    )
}
