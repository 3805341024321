import React from 'react'
import NavBarComponent from '../Nav-Bar/nav-bar.component'
import { Footer } from '../Footer/footer.component'

function Regeln() {
    return (
        <div>
            <NavBarComponent></NavBarComponent>

            <section className="dark:bg-gray-800 dark:text-gray-100">
                <div className="container flex flex-col justify-start p-4 mx-auto md:p-8 text-left">
                    <h2 className="mb-12 text-4xl font-bold leading-none text-center sm:text-5xl">Community-Richtlinien</h2>
                    <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 divide-gray-700 ">
                        <details open="Open">
                            <summary className="py-2 text-2xl font-bold outline-none cursor-pointer focus:underline">Allgemein</summary>
                            <div className="pb-4">
                                <p class="text-lg">
                                Die Registrierung ist vollkommen kostenlos und unverbindlich! Mit der Registrierung erklärst Du, dass Du mit den folgenden Punkten einverstanden bist.
                                Folgende Punkte sind unter anderem nicht gestattet:
                                    </p>
                            </div>
                            <p class="text-xl font-bold">Rechtswidrige oder menschenverachtende Inhalte
                            </p>

                            <p class="text-xl font-bold">Hilfestellungen und/oder Links bezüglich illegal erworbener Software sowie Urheberrechts- und Lizenzverletzungen.
                            </p>
                           
                            <p class="text-xl font-bold">Provokationen und Beleidigungen

                            </p>
                            

                            <p class="text-xl font-bold">Multi-Accounts


                            </p>
                           

                            <p class="text-xl font-bold">Partnerprogramm-, Werbe-, und Diebstahl-Links mit Referrer sowie Werbung für eigene Produkte oder Websites, private Ebay Auktionen und ähnliches was als Selbstpromotion anzusehen wäre


                            </p>
                            

                            <p class="text-xl font-bold">Angebote von Privatpersonen

                            </p>
                            

                            <p class="text-xl font-bold">Angebote zu Tabakwaren, Tabakprodukten sowie E-Zigaretten und Liquids

                            </p>
                           

                            <p class="text-xl font-bold">Angebote zu Pfeffersprays oder anderen Produkten, die vornehmlich zur Personenabwehr gekauft werden

                            </p>
                            

                            <p class="text-xl font-bold">Pornographie

                            </p>
                            <p class="text-xl font-bold">Spam in jeglicher Form


                            </p>
                            <p class="text-xl font-bold">Die Verwendung von Signaturen


                            </p>
                        
                            <p class="text-xl ">Eingestellte Inserate, Kommentare oder Beiträge können von Moderatoren nach eigenem Ermessen editiert, verschoben und gelöscht werden. Bei einem Verstoß wirst Du darauf hingewiesen und gegebenenfalls verwarnt. Solltest Du regelmässig und bewusst gegen die Regeln verstoßen wird dein Account gegebenenfalls auch gesperrt. Übertreibst Du es extrem, wird Dein Account auch ohne Vorwarnung gesperrt. Alle gespeicherten Daten werden nicht an Dritte weitergegeben. Wir hassen Spam mindestens genauso sehr wie Du.
</p>
                            
                        </details>


                       

                    </div>
                </div>
            </section>
            <Footer></Footer>

        </div>
    )
}

export default Regeln