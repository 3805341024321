import React from 'react'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapTest from '../Map/mapTest';
import NewFilter from './with_dropdown_product_filters';
import Card from './card.component';
import Navbar from '../Nav-Bar/nav-bar.component'
import PropTypes from 'prop-types';
import NoResult from './images/no-result.png'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { loadGigs } from '../../actions/gigs';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'antd';
import { SearchIcon } from '@heroicons/react/solid'
import { Footer } from '../Footer/footer.component';

mapboxgl.accessToken = 'pk.eyJ1IjoibWVyd2luZ2VlZSIsImEiOiJja3o5cTd1YXIxN3E1Mm5uOThjbndrdmsyIn0.Buc_M-neLbBd5Lx4SUNCoA';

function Listing({ data, loadGigs, id }) {


    const { slug } = useParams();
    const navigate = useNavigate();
    const [Search, setSearch] = useState('');
    const [SearchKey, setSearchKey] = useState('')
    const [PageNo, setPageNo] = useState(1)
    const [params, setParams] = useState('')
    const [searchbarkeyword, setsearchbarkeyword] = useState('');


    var Mapstyle = {
        height: '1540px',
        width: '570px',
        zIndex: 1,
    }



    const HandlePaginationChange = (value) => {
        navigate("../List/" + SearchKey + params + "page=" + value, { replace: true })
        navigate(0, { replace: true })
    };


    const HandleFilter = (Filterproperties) => {
        console.log("Filter" + " :: " + Filterproperties)
        if (Filterproperties == "") {
            Filterproperties = "sort=new"
        }
        navigate("../List/" + Filterproperties)
        navigate(0, { replace: true })

    }


    const handleSearchbar = (event) => {
        setsearchbarkeyword(event.target.value)
    }



    const OnSubmit = (e) => {
        console.log("new search: " + searchbarkeyword)
        navigate("/list/search=" + searchbarkeyword)
        navigate(0, { replace: true })
    };

    const showResult = () => {

        if (data.gigPayload.data.results.length === 0) {
            return (
                <div className="flex flex-col -space-y-12">
                    <h1 className="text-4xl py-4">Keine Resultate</h1>

                    <div>
                        <img src={NoResult} className=" mx-auto h-96 w-96"></img>
                    </div>
                </div>
            )
        }


        return (

            <div className="min-h-screen max-h-screen space-y-8 max-w-7xl mx-auto">
                <div className="md:flex">
                    {/* Map*/}
                    <div className=" hidden lg:block flex py-4 w-[36rem]  ">
                        <div className=" ">
                            <MapTest style={Mapstyle} content={data.gigPayload.data.results} listing={true} />
                        </div>
                    </div>
                    <div className="w-full">
                        <Card results={data.gigPayload.data.results} id={id}></Card>
                    </div>
                </div>

                <div className>
                    {/* Pagination*/}
                    <Pagination
                        defaultCurrent={PageNo}
                        defaultPageSize={5} //default size of page
                        onChange={HandlePaginationChange}
                        total={data.gigPayload.data.count} //total number of card data available
                    />
                </div>
                <Footer></Footer>
            </div>
        )
    }

    const showLoading = () => {
        return (
            <div>
                <div className="flex items-center justify-center space-x-2">
                    <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow inline-block w-12 h-12 bg-current rounded-full opacity-0" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }


    useEffect(() => {

        loadGigs(slug)
        const paramsWithKey = slug.split('&')

        setSearch(paramsWithKey[0].split('=')[1])

        for (var i = 0; i < paramsWithKey.length; i++) {

            if (paramsWithKey[i].indexOf('search=') > -1) {
                setSearchKey(paramsWithKey[i] + "&")

            }
            if (paramsWithKey[i].indexOf('page=') > -1) {
                setPageNo(paramsWithKey[i].split('=')[1])

            }

        }


    }, []);


    //https://stackoverflow.com/questions/64566405/react-router-dom-v6-usenavigate-passing-value-to-another-component

    return (

        <div>
            <Navbar />
            <div className="max-w-7xl mx-auto p-4">
                <div className="flex justify-end ">

                    <form
                        className="w-full"
                        id='search_form'
                        onSubmit={e => OnSubmit(e)}>


                        <div className="relative">
                            <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input
                                name="searchdata"
                                className="block w-full shadow-2xl border border-gray-200 rounded-lg py-4 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 sm:text-sm"
                                placeholder="Suche nach Lieferwagen, ....."
                                value={searchbarkeyword}
                                onChange={handleSearchbar}
                            />
                            <button type="submit" className="absolute bg-amber-200 shadow-lg focus:bg-amber-300 hover:bg-amber-100 rounded-xl right-2.5 bottom-2.5  text-sm px-4 py-2 0">Suchen</button>

                        </div>
                    </form>


                </div>
                {Search == "new" &&
                    <h2 className="text-3xl p-8 py-12 text-left font-bold text-gray-900">Die neuesten Inserate</h2>
                }

                {Search == "best" &&
                    <h2 className="text-3xl p-8 py-12 text-left font-bold text-gray-900">Die meist gelikten Inserate</h2>
                }

                {Search == "costly" &&
                    <h2 className="text-3xl p-8 py-12 text-left font-bold text-gray-900">Die teuersten Inserate</h2>
                }

                {Search == "cheap" &&
                    <h2 className="text-3xl p-8 py-12 text-left font-bold text-gray-900">Die günstigsten Inserate</h2>
                }

                {(Search != "new" && Search!="best" && Search!="costly" && Search!="cheap") &&
                    <h2 className="text-3xl p-8 py-12 text-left font-bold text-gray-900">Resultate für: {Search}</h2>
                }


            </div>
            <NewFilter data={slug} HandleFilter={HandleFilter} setParams={setParams} set></NewFilter>
            {data.loading ? showLoading() : showResult()}
        </div >
    )

}

Listing.propTypes = {
    loadGigs: PropTypes.func.isRequired,
    id: PropTypes.number
};


const mapStateToProps = state => ({
    data: state.gig,
    id: state.auth.id
});

export default connect(mapStateToProps, { loadGigs })(Listing);
