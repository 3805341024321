import Details from "./steps/details.component";
import Final from "./steps/final.component";
import StepperControl from "./stepper.control.component";
import Stepper from "./stepper.component";
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
import { Navigate } from 'react-router-dom';

// REDUX
import { connect } from 'react-redux';
import { editGig } from '../../actions/gigs';
import { useForm } from "react-hook-form";
import { useStepperContext } from "./stepper.context.component";



const Edit = (props) => {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const { userData, setUserData } = useStepperContext();
    const { slug } = useParams()

 

    useEffect(() => {

        console.log(props.content.title)

    },[])

    const [formData, setFormData] = useState({
        title: '',
        category: '',
        description: '',
        conditions: '',
        photolist: null,
        location: '',
        zip: '',
        lat: '',
        lon: '',
        canton: '',
        city: '',
        hour: '',
        day: '',
        week: '',
        duration: '',
        status: ''
    });

    const steps = [
        "Beschreibung",
        "Abschluss",
    ];

    const displayStep = (step) => {
        switch (step) {
            case 1:
                return <Details form={useForm} content={props.content} />;

            case 2:
                return <Final />;
            default:
        }
    };


    const handleClick = (direction) => {

        if (currentStep != steps.length ) {
            

            if (userData.title == "") {
                setUserData({ title: props.content.title })
            }

            if (userData.category == "") {
                setUserData({ category: props.content.category })
            }

            if (userData.city == "") {
                setUserData({ city: props.content.city })
            }

            if (userData.conditions == "") {
                setUserData({ conditions: props.content.conditions })
            }

            if (userData.description == "") {
                setUserData({ description: props.content.description })
            }

            if (userData.lat == "") {
                setUserData({ lat: props.content.lat })
            }

            if (userData.location == "") {
                setUserData({ location: props.content.location })
            }

            if (userData.lon == "") {
                setUserData({ lon: props.content.lon })
            }

            if (userData.price_day == "") {
                setUserData({ price_day: props.content.price_day })
            }

            if (userData.price_hour == "") {
                setUserData({ price_hour: props.content.price_hour })
            }

            if (userData.price_week == "") {
                setUserData({ price_week: props.content.price_week })
            }

            if (userData.rent_duration == "") {
                setUserData({ rent_duration: props.content.rent_duration })
            }

            if (userData.zip == "") {
                setUserData({ zip: props.content.zip })
            }


            console.log("Executed ?")
            let slugdata = { slug };
            let id = slugdata.slug

            props.editGig(userData, id)
        }

        let newStep = currentStep;
        direction === "next" ? newStep++ : newStep--;
        // check if steps are within bounds
        newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);


    };


    const [currentStep, setCurrentStep] = useState(1);
    const [files, setFiles] = useState('');
    const content = []


    const onChange = e => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });

    const uploadFileHandler = (event) => {
        setFiles(event.target.files);
    };

    const uploadImage = e => {
        setFormData({ photo: e.target.files })
        console.log(formData)
    };

    const uploadImage1 = e => {
        setFormData({ ...formData, photo: e.target.files[0] })
    };

    const uploadImage2 = e => {
        setFormData({ ...formData, photo: e.target.files[0] })
    };

    const uploadImage3 = e => {
        setFormData({ ...formData, photo: e.target.files[0] })
    };

    //https://stackoverflow.com/questions/43638938/updating-an-object-with-setstate-in-react

    const { title, category, description, photo, status, price } = formData;




    if (props.isAuthenticated) {

        return (
            <div class="py-8">
                {/*https://github.com/QuickNuggets/multi-step-form*/}
                <div className="mx-auto  bg-white shadow-xl pb-2 border md:w-1/2">
                    {/* Stepper */}
                    <div className="horizontal container mt-5 ">
                        <Stepper steps={steps} currentStep={currentStep} />

                        <div className="my-10 p-10 ">

                            {displayStep(currentStep)}

                        </div>
                    </div>

                    {/* navigation button */}
                    {currentStep !== steps.length && (
                        <StepperControl
                            handleClick={handleClick}
                            currentStep={currentStep}
                            steps={steps}
                        />
                    )}
                </div>
            </div>

        )

    }

    if (!props.isAuthenticated) {
        return (
            <div>
                <Navigate to="/Login" replace={true} />
            </div>
        )
    }

}

Edit.propTypes = {
    isAuthenticated: PropTypes.bool,
    editGig: PropTypes.func.isRequired,
    newCreateGig: PropTypes.func.isRequired,
};



const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { editGig })(Edit);