import React from 'react'
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from "@cloudinary/url-gen";
import errorLogo from '../Images/Asset/noimage.png'

// Import any actions required for transformations.
import { fill, limitFill, limitFit, pad } from "@cloudinary/url-gen/actions/resize";
import { border, predominant, predominantGradient } from '@cloudinary/url-gen/qualifiers/background';

function ImageFound(props) {

  
  if (props.boolerror===false) {
    return <AdvancedImage cldImg={props.myImage} />

  } else {
    return <img src={errorLogo} width={props.width} height={props.height}/>
  }
}


function ThumbnailCloudinary(props) {


  let ImageID = props
  let boolerror = false



  let idx = 0;

  if (ImageID.ImagePath.length == 0) {
    boolerror = true
  }

  if (ImageID.ImagePath.length > 0) {
    ImageID = ImageID.ImagePath[0].image

    boolerror = false
    idx = ImageID.indexOf('upload');
    ImageID = ImageID.substring(idx + 7, ImageID.length)
  }


  

  // 2. Set your cloud name
  //========================

  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'nebula-cloudinary'
    }
  });


  // 3. Get your image
  //===================

  // Instantiate a CloudinaryImage object for the image with the public ID, 'docs/models'.
  const myImage = cld.image(ImageID);



  // 4. Transform your image
  //=========================

  // Resize to 400 x 400 pixels using the 'fill' crop mode.
  myImage.resize(pad().width(props.width).height(props.height).background(border()));



  // 5. Deliver your image
  // =========================

  // Render the image in a React component.
  return (
    <div>
      <ImageFound boolerror={boolerror} myImage={myImage} width={props.width} height={props.height}/>
    </div>
  )
}

export default ThumbnailCloudinary