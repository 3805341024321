import {
  USER_LOADED,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REFRESH_TOKEN,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_SUCCESS,
  GOOGLE_AUTH_FIRST,
  GOOGLE_AUTH_SUCCESS,
  GOOGLE_AUTH_FAIL,
} from './types';
import setAlert from './alert';
import { toast } from "react-toastify";
import AuthService from "../services/auth.service"
import { Navigate } from 'react-router-dom';



export const registerUser = (user) => (dispatch) => {
  dispatch(setAlert("Lade...", 200, 'loading'));

  return AuthService.register(user).then(
    
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: { user: response },
      }
      );
      toast.dismiss()
      dispatch(setAlert("Bitte bestätige deine Email", 200, 'success'));

      // DISPATCH SET ALERT

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        toast.dismiss()
        console.log(error.response)


          if(error.response.data.password1){
            console.log(error.response.data.password1[0])
            dispatch(setAlert(error.response.data.password1[0], 200, 'error'));
          }
          if(error.response.data.email){
            dispatch(setAlert(error.response.data.email[0], 200, 'error'));
            console.log(error.response.data.email[0])
          }
          if(error.response.data.non_field_errors){
            dispatch(setAlert(error.response.data.non_field_errors[0], 200, 'error'));
            console.log(error.response.data.non_field_errors[0])
          }

      dispatch({
        type: REGISTER_FAIL,
      });


      return Promise.reject();
    }
  );
};

export const loginUser = (user) => (dispatch) => {
  dispatch(setAlert("Lade...", 200, 'loading'));

  const username = user //Weird fix as username contains both username and password
  return AuthService.login(username).then(
    (data) => {
      toast.dismiss()
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { username: data.username },
      });


      // DISPATCH LOAD USER
      dispatch(loadUser())



      // DISPATCH SET ALERT
      //

      return Promise.resolve();
    },
    (error) => {
      toast.dismiss()
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });
      if(error!=undefined){
        dispatch(setAlert(error.response.data.non_field_errors[0], 200, 'error'));
      }


      return Promise.reject();
    }
  );
};

export const facebookLogin = (token) => (dispatch) => {
  dispatch(setAlert("Lade...", 200, 'loading'));
  return AuthService.facebookLogin(token).then(
    (data) => {
      toast.dismiss()
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { username: data.first_name },
      });


      // DISPATCH LOAD USER
      dispatch(loadUser())


      // DISPATCH SET ALERT
      //

      return Promise.resolve();
    },
    (error) => {
      toast.dismiss()
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });
      if(error!=undefined){
        dispatch(setAlert(error.response.data.non_field_errors[0], 200, 'error'));
      }


      return Promise.reject();
    }
  );
};

export const googleLogin = (token) => (dispatch) => {
  console.log(token)
  return AuthService.googleSignin(token).then(
    
    (data) => {
      dispatch({
        type: GOOGLE_AUTH_FIRST,
      });

      dispatch(loadUser())


      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GOOGLE_AUTH_FAIL,
      });

      if(error!=undefined){
        dispatch(setAlert(error.response.data.non_field_errors[0], 200, 'error'));
      }
      return Promise.reject();
    }
  );
};


export const loadUser = () => (dispatch) => {
  return AuthService.loadUser().then(
    (data) => {
      dispatch({
        type: USER_LOADED,
        payload: {
          username: data.username,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          id: data.id
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const logoutUser = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT_SUCCESS,
  });
  // DISPATCH SET ALERT
  dispatch(setAlert('Erfolgreich Logged off', 200, 'info'));
};

export const refreshToken = (accessToken) => (dispatch) => {
  dispatch({
    type: REFRESH_TOKEN,
    payload: accessToken,
  })
}

export const resetPassword = (email) => (dispatch) => {
  dispatch(setAlert("Lade...", 200, 'loading'));

  return AuthService.resetPassword(email).then(
    (data) => {
      toast.dismiss()
      dispatch(setAlert("E-mail mit Passwort zurücksetzen versand", 200, 'success'));
      return Promise.resolve();
    },
    (error) => {
      toast.dismiss()
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: PASSWORD_RESET_FAIL,
      });

      console.log(error.response)

      if(error.response.data.email){
        dispatch(setAlert(error.response.data.email[0], 200, 'error'));
        console.log(error.response.data.email[0])
      }

      dispatch(setAlert(error.response.data.message, 200, 'error'));

      return Promise.reject();
    }
  );
};

export const setNewPassword = (data) => (dispatch) => {
  dispatch(setAlert("Lade...", 200, 'loading'));

  return AuthService.setNewPassword(data).then(
    (data) => {
      toast.dismiss()
      dispatch({
        type: PASSWORD_RESET_SUCCESS,
      });

      return Promise.resolve();
    },
    (error) => {
      toast.dismiss()
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: PASSWORD_RESET_FAIL,
      });


      if(error.response.data.new_password1){
        console.log(error.response.data.new_password1[0])
        dispatch(setAlert(error.response.data.new_password1[0], 200, 'error'));
      }
      if(error.response.data.new_password2){
        console.log(error.response.data.new_password2[0])
        dispatch(setAlert(error.response.data.new_password2[0], 200, 'error'));
      }
      if(error.response.data.email){
        dispatch(setAlert(error.response.data.email[0], 200, 'error'));
        console.log(error.response.data.email[0])
      }
      if(error.response.data.non_field_errors){
        dispatch(setAlert(error.response.data.non_field_errors[0], 200, 'error'));
        console.log(error.response.data.non_field_errors[0])
      }

      dispatch(setAlert("Fehler, bitte überprüfe deine Angaben", 200, 'error'));

      return Promise.reject();
    }
  );
};


