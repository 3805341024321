import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import OnlyLogo from '../../Images/Logo/Only_Logo.png'
import { Footer } from '../Footer/footer.component';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import GoogleButton from './GoogleButton.component';

// REDUX
import { connect } from 'react-redux';
import { loginUser, facebookLogin } from '../../actions/auth';
import setAlert from '../../actions/alert';

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  })


const Login = ({ isAuthenticated, loginUser, setAlert, facebookLogin }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });



  const responseFacebook = (response) => {
    facebookLogin(response.accessToken)
  }

  if (isAuthenticated) {
    navigate("/")
  }


  const onChange = e => setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });

  const { email, password } = formData;

  const onSubmit = e => {
    e.preventDefault();

    if (!email.trim() || !password.trim()) {
      setAlert('Please fill out all fields!');
    } else {
      loginUser(formData)
      setFormData({
        ...formData,
        email: '',
        password: ''
      });
    }
  };


  return (
    <div>
      <div className="min-h-screen text-left flex flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-24 w-auto"
            src={OnlyLogo}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">anmelden</h2>
          <p className="mt-2 text-center text-sm text-gray-600 max-w">
            oder{' '}
            <a href="/register" className="font-medium text-indigo-600 hover:text-indigo-500">
              gratis registrieren
            </a>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 md:shadow-2xl sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={e => onSubmit(e)} method="POST" >
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id='email'
                    type='text'
                    name='email'
                    value={email}
                    placeholder='Email eingeben'
                    onChange={e => onChange(e)}
                    autoComplete='email'
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Passwort
                </label>
                <div className="mt-1">
                  <input
                    id='password'
                    type='password'
                    name='password'
                    value={password}
                    placeholder='Passwort eingeben'
                    onChange={e => onChange(e)}
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">


                <div className="text-sm">
                  <a href="PasswordReset" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Passwort vergessen?
                  </a>
                </div>
              </div>

              <div>
                <button
                  value={1}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Anmelden
                </button>
              </div>
            </form>

            <div class="mt-6">
              <div class="relative">
                <div class="absolute inset-0 flex items-center">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-center text-sm">
                  <span class="px-2 bg-white text-gray-500">
                    oder anmelden mit
                  </span>
                </div>
              </div>

              <div class="mt-6 grid grid-cols-2 gap-3">
                <div>
                  <GoogleOAuthProvider clientId="48395556566-2s2ajm341k3jgl44arks0d0req9dfkr9.apps.googleusercontent.com">
                    <GoogleButton></GoogleButton>
                  </GoogleOAuthProvider>
                </div>
                <div>
                  <FacebookLogin
                    appId="681136153313647"
                    callback={responseFacebook}
                    isMobile={false}
                    redirectUri='https://mietplace.ch'
                    disableMobileRedirect={true}
                    render={renderProps => (
                      <div onClick={renderProps.onClick}>
                        <a href="#" class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                          <span class="sr-only">Sign in with Facebook</span>
                          <svg class="w-5 h-5 text-indigo-500" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                            <path fill-rule="evenodd" d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z" clip-rule="evenodd" />
                          </svg>
                        </a>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Footer></Footer>



    </div>
  )
}

Login.propTypes = {
  isAuthenticated: PropTypes.bool,
  loginUser: PropTypes.func.isRequired,
  facebookLogin: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { loginUser, facebookLogin, setAlert })(Login);