import {
  ALLGIGS_LOADED,
  ALLGIGS_LOADING,
  ALLGIGS_ERROR,
  GET_LATEST_GIGS
} from '../actions/types';

const initialState = {
  loading: true,
  gigPayload: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ALLGIGS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ALLGIGS_LOADED:
      return {
        ...state,
        loading: false,
        gigPayload: payload
      };

    case GET_LATEST_GIGS:
      return {
        ...state,
        loading: false,
        gigPayload: payload
      };

    case ALLGIGS_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};