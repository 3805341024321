import api from "./api";
import TokenService from "./token.service";

class GigService {

    async getPublicContent() {
        const response = await api.get(`/`);
        return response
    }

    async getLatestContent(){
        const response = await api.get(`/get_latest_gigs/`)
        return response
    }

    async getPageSearch(data) {
        const response = await api.get(`/?`+ data);
        return response
    }

    async getPagefromURL(url){
        const response = await api.get(url)
        return response;
    }

    async getNextPagePublicContent(link){
        console.log(link)
        let id = link.charAt(link.length-1)
        if(id==="/")
            id = ""
        const response = await api.get(`/?page=`+id);
        return response
    }

    async getPrevPagePublicContent(link){
        let id = link.charAt(link.length-1)
        const response = await api.get(`/?page=`+id);
        return response
    }


    async getPrivateContent() {
        const response = await api.get(`/my_gigs/`);
        console.log("Get User gigs")
        return response
    }

    async getFavorites() {
        const response = await api.get(`/my_favorites/`);
        console.log("Get Favorites")
        return response
    }


    async getMyInquiries() {
        const response = await api.get(`/my_inquiries/`);
        console.log("Get MyInquiries")
        return response
    }

    async getGig(id) {
        const response = await api.get(`/` + id + `/`);
        return response
    }

    async deleteGig(id) {
        const response = await api.delete(`/` + id + `/`);
        return response
    }

    async getSearchContent(data) {
        const response = await api.get(`/?search=`+ data,);
        return response
    }

    async getCategoryContent(data) {
        const response = await api.get(`/?category=`+ data,);
        return response
    }    

    async editGig(data, id) {
        var bodyFormData = new FormData();

        console.log(data)
        
        if(data.title !== "" && data.title !==undefined ){
            bodyFormData.append('title', data.title);
            console.log("Title modified")
        }

        if( data.description !=="" && data.description !==undefined){
            bodyFormData.append('description', data.description);
            console.log("description modified")
        }

        if(data.price_day !== "" && data.price_day !==undefined){
            bodyFormData.append('price_max', data.price_day);
            console.log("price modified")
        }

        if(data.price_week !== "" && data.price_week !==undefined){
            bodyFormData.append('price_min', data.price_week);
            console.log("status modified")
        }

        if(data.price_hour !== "" && data.price_hour !==undefined){
            bodyFormData.append('photo', data.price_hour);
            console.log("photo modified")
        }

        if(data.category !== "" && data.category !==undefined){
            bodyFormData.append('category', data.category);
            console.log("category modified")
        }

        if( data.conditions !== '' && data.conditions !==undefined){
            bodyFormData.append('conditions', data.conditions);
            console.log("conditions modified")
        }

        if(data.city!==''&& data.city !==undefined){
            bodyFormData.append('city', data.city);
            console.log("city modified")
        }

        if(data.zip !== ''&& data.zip !==undefined){
            bodyFormData.append('zip', data.zip);
            console.log("zip modified")
        }


        if(data.lat !== ''&& data.lat !==undefined){
            bodyFormData.append('lat', data.lat);
            console.log("lat modified")
        }


        if(data.lon !== ''&& data.lon !==undefined){
            bodyFormData.append('lon', data.lon);
            console.log("lon modified")
        }

        if( data.rent_duration&& data.rent_duration !==undefined ){
            bodyFormData.append('rent_duration', data.rent_duration);
            console.log("rent_duration modified")
        }

        if(data.canton !== ''&& data.canton !==undefined){
            bodyFormData.append('canton', data.canton);
            console.log("rent_duration modified")
        }

        if(data.location !== ''&& data.location !==undefined){
            bodyFormData.append('location', data.location);
            console.log("location modified")
        }

        if(data.status !== ''&& data.status !==undefined){
            bodyFormData.append('status', data.status);
            console.log("status modified")
        }
        
        const response = await api.patch(`/` + id + `/`, bodyFormData);

        return response.data;
    }


    async createNewGig(data) {

        var formData = new FormData();
        formData.append('title', data.title);
        formData.append('category', data.category);
        formData.append('description', data.description);
        formData.append('conditions', data.conditions);
        formData.append('lat', data.lat);
        formData.append('lon', data.lon);
        formData.append('canton', data.canton);
        formData.append('city', data.city);
        formData.append('price_hour', data.price_hour);
        formData.append('price_day', data.price_day);
        formData.append('price_week', data.price_week);
        formData.append('rent_duration', data.rent_duration);
        formData.append('zip', data.zip);
        formData.append('status', true);

        console.log(data.fileList[0].originFileObj)
        
        for(let i = 0; i < data.fileList.length; i++){
            formData.append('photo_'+i, data.fileList[i].originFileObj)
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        const response = await api.post("/new_create_gig/", formData);
        return response.data;
    }


    async like(gig_id) {
        var bodyFormData = new FormData();
        bodyFormData.append('gig', gig_id);
        const response = await api.post(`/new_like/`,bodyFormData);
        return response
    }

    async deleteImage(img_id) {
        var bodyFormData = new FormData();
        bodyFormData.append('img_id', img_id)
        const response = await api.post(`/delete_img/`,bodyFormData);
        return response
    }

    async dislike(gig_id) {
        var bodyFormData = new FormData();
        bodyFormData.append('gig', gig_id);
        const response = await api.post(`/new_dislike/`,bodyFormData);
        return response
    }

    async postreview(gig_id, text) {
        var bodyFormData = new FormData();
        bodyFormData.append('gig', gig_id);
        bodyFormData.append('review', text);
        const response = await api.post(`/review/`,bodyFormData);
        return response
    }

    async inquire(start_date,end_date,gig_id, remark) {
        var bodyFormData = new FormData();
        bodyFormData.append('start_date', start_date);
        bodyFormData.append('end_date', end_date);
        bodyFormData.append('gig', gig_id);
        bodyFormData.append('remark', remark);
        const response = await api.post(`/inquire/`,bodyFormData);
        return response
    }
    async add_favourites(gig_id) {
        var bodyFormData = new FormData();
        bodyFormData.append('gig', gig_id);
        const response = await api.post(`/add_favorite/`,bodyFormData);
        return response;
    }
    async createAlert(message) {
        return message;
    }
}

export default new GigService();