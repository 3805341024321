import React from 'react'
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { likeGig } from '../../actions/gigs';

function findById(source, id) {
  for (var i = 0; i < source.length; i++) {

    if (source[i].user === id) {
      return true;
    }
  }
  return false;
}

function LikeButton(props,) {
  const navigate = useNavigate();





  const OnLikeButton = (e) => {


    if (!props.isAuthenticated) {
      navigate("/login")
      return
    }

    props.likeGig(props.contentID)

  }



  if (findById(props.likes, props.userID)) {
    return (

      <div className="">
        <button onClick={() => OnLikeButton()} className="relative ">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-red-500 fill-transparent hover:fill-red-500" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
          </svg>
          <span
            className="absolute top-0 right-0 px-2 py-1 translate-x-1/2  border border-white rounded-full text-xs text-gray-900 bg-amber-200 font-bold">{props.noLikes}</span>
        </button>
      </div>

    )
  }

  return (
    <div>
      <div className="md:py-6">
        <button onClick={() => OnLikeButton()} className="relative ">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-red-500 fill-transparent hover:fill-red-500" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
          </svg>
          <span
            className="absolute top-0 right-0 px-2 py-1 translate-x-1/2  border border-white rounded-full text-xs text-gray-900 bg-amber-200 font-bold">{props.noLikes}</span>
        </button>

      </div>



    </div>
  )
}

LikeButton.propTypes = {
  isAuthenticated: PropTypes.bool,
  likeGig: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { likeGig })(LikeButton)