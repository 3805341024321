import { useNavigate, useSearchParams } from 'react-router-dom';
import { Fragment } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/auth';
import { searchGig } from '../../actions/gigs';
import React, { useState } from 'react';
import setAlert from '../../actions/alert';
import { Menu, Popover, Transition } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/solid'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { useEffect, useRef } from "react";
import Logo from '../../Images/Logo/Logo.png'
import Profile from './images/profile.svg'
import {
  MusicNoteIcon,
  BookOpenIcon,
  AcademicCapIcon,
  DesktopComputerIcon,
  CogIcon,
  TruckIcon,
  PencilIcon,
  HomeIcon,
  StopIcon,
  VideoCameraIcon,
  VariableIcon,
  UserIcon
} from '@heroicons/react/outline'

import { ChevronDownIcon } from '@heroicons/react/solid'

const categories = [
  {
    name: 'Audiogeräte',
    description: 'Suche nach Equipments, Lautsprecher, Vynyl etc',
    href: '/list/category=Audio',
    cat: 'DM',
    icon: MusicNoteIcon,
  },
  {
    name: 'Bücher',
    description: 'Suche nach Bücher, Magazine, Literatur etc',
    href: '/list/category=Bücher',
    icon: BookOpenIcon,
  },
  {
    name: 'Computer',
    description: 'Suche nach Computerhardware, GPU, CPU etc',
    href: '/list/category=Computer',
    icon: DesktopComputerIcon,
  },
  {
    name: 'Diensleistungen',
    description: 'Suche nach Dienstleistungen',
    href: '/list/category=Diensleistungen',
    icon: CogIcon,
  },
  {
    name: 'Fahrzeuge',
    description: 'Suche nach Auto, Lieferwagen, Limousine etc',
    href: '/list/category=Fahrzeuge',
    icon: TruckIcon,
  },
  {
    name: 'Handwerk',
    description: 'Suche nach Bohrmaschine, Hochdruckreiniger etc',
    href: '/list/category=Handwerk',
    icon: PencilIcon,
  },
  {
    name: 'Immobilien',
    description: 'Suche nach Immobilien, Wohnungen, Büroflächen etc',
    href: '/list/category=Immobilien',
    icon: HomeIcon,
  },
  {
    name: 'Möbel',
    description: 'Suche nach Tische, Stühle etc',
    href: '/list/category=Möbel',
    icon: StopIcon,
  },
  {
    name: 'Kleidung',
    description: 'Suche nach Kostüme, Masken etc',
    href: '/list/category=Kleidung',
    icon: AcademicCapIcon,
  },
  {
    name: 'TV',
    description: 'Suche nach Projektoren, Bildschirme etc',
    href: '/list/category=TV',
    icon: VideoCameraIcon,
  },
  {
    name: 'Sonstiges',
    description: 'Suche nach diverse Objekte etc zum mieten ',
    href: '/list/category=Sonstiges',
    icon: VariableIcon,
  },
]


const userNavigation = [
  { name: 'Meine Anfragen', href: '/myInquiries' },
  { name: 'Meine Inserate', href: '/profile' },
  { name: 'Favoriten', href: '/favorites' },
  { name: 'Abmelden', href: '#', function: 'LOGOUT' },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



function Navbar({ isAuthenticated, username, first_name, last_name, logoutUser, searchGig, onUpdate, activateFilter }) {

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchKeyword, setSearchKeyword] = useState([])

  const navigate = useNavigate();
  const [initMount, setInitMount] = useState(false)
  const [content, setContent] = useState(null);


  // This function will be called whenever the text input changes
  const searchHandler = (event) => {
    let search;
    if (event.target.value) {
      search = {
        search: event.target.value
      }

    } else {
      search = undefined;
    }

    setSearchParams(search);
    setSearchKeyword(search)
  }

  const OnNavigate = (href) => {
    navigate(href)
  }

  //https://wtwerner.medium.com/adding-login-logout-signup-to-a-navbar-with-react-and-redux-729f806258bb
  const displayAuthName = () => {
    if (username === null) {
      return (
        <a></a>
      )

    } else {
      <a>{username}</a>
    }
  }

  useEffect(() => {
    if (!initMount) {
      setInitMount(true);
    }
    else {

      navigate('/list', { state: { content: content } })
    }
  }, [content]) // 


  const onFunction = (data) => {
    switch (data) {
      case 'LOGOUT': {
        logoutUser()

      }
      default:
        return null;
    }
  }

  const authLinks = () => {


    return (
      <Fragment>

        <a
          href="/create"
          className="ml-8 inline-flex items-center justify-center px-4 rounded-lg py-2 border border-transparent shadow-xl text-base font-medium text-white bg-blue-500 hover:text-white hover:bg-blue-700"
        >
          Gratis inserieren
        </a>

        {/* Profile dropdown */}
        <Menu as="div" className="flex-shrink-0 relative px-4 ">
          {({ open }) => (
            <>
              <div class="flex">
                <Menu.Button className="bg-white rounded-full flex focus:outline-none ">
                  <a
                    className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium  text-black  "
                  >
                    {username}
                  </a>

                  <span className="sr-only">Open user menu</span>

                  <img
                    className="h-8 w-auto sm:h-10"
                    src={Profile}
                    alt=""
                  />

                </Menu.Button>
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none"
                >
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          onClick={() => onFunction(item.function)}
                          href={item.href}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block py-2 px-4 text-sm text-gray-700 text-left'
                          )}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </Fragment>
    )
  }


  const authLinksMobile = () => {

    return (
      <div class=" py-8 ">
        <div className='flex space-y-2 flex-col items-center text-xl font-bold'>

          <div>
            <img class="w-12 h-12rounded-full"
              src={Profile} alt="Neil image" />
          </div>

          <div> Hallo {username}
          </div>
        </div>

        <div class="py-8">
          <a
            href="/create"
            class=" items-center rounded-lg justify-center p-4  py-2 border border-transparent shadow-xl text-base font-medium text-white bg-blue-500 hover:text-white hover:bg-blue-700"
          >
            Gratis Inserieren
          </a>
        </div>

        <div class="py-2">
          <a
            href="/profile"
            class="py-2 border border-transparent  text-base font-medium text-blue-900 "
          >
            Meine Inserate
          </a>
        </div>


        <div class="py-2">
          <a
            href="/myInquiries"
            class="py-2 border border-transparent  text-base font-medium text-blue-900 "
          >
            Meine Anfragen
          </a>
        </div>

        <div class="py-2">
          <a
            href=""
            class="items-center justify-center py-2 border border-transparent  text-base font-medium text-blue-900 "
          >
            Favoriten
          </a>
        </div>

        <div class="py-2">
          <button
            onClick={() => onFunction('LOGOUT')}
            class="items-center justify-center  border border-transparent  text-base font-medium text-red-500  hover:bg-blue-700"
          >
            Logout
          </button>
        </div>
      </div>
    )
  }

  const guestLinksMobile = () => {

    return (

      <Fragment>
        <div class="flex justify-center">
          <div class="py-4">
            <h1 class=" text-2xl py-8">Herzlich Willkommen </h1>
            <div className=''>
              <a
                href="/Login"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-lg shadow-xl text-base font-medium text-white bg-red-400 "
              >
                Anmelden
              </a>
              <a
                href="/Register"
                className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-lg shadow-xl text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Registrieren

              </a>
            </div>

            <div className='py-8'>
              <a
                href="/login"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-lg shadow-xl text-base font-medium text-white bg-indigo-400 hover:bg-indigo-700"
              >
                Gratis inserieren

              </a>
            </div>

          </div>

        </div>

      </Fragment>
    )
  }

  const guestLinks = () => {

    return (
      <Fragment>

        <a
          href="/Login"
          className="text-base font-medium text-gray-500 hover:text-gray-900"
        >
          Anmelden
        </a>
        <a
          href="/Register"
          className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-lg shadow-xl text-base font-medium text-white  hover:text-white bg-indigo-600 hover:bg-indigo-700"
        >
          Registrieren

        </a>
        <div className='px-4'>
          <a
            href="/login"
            className=" inline-flex items-center justify-center px-4 rounded-lg py-2 border border-transparent shadow-xl text-base font-medium text-white bg-blue-500 hover:text-white hover:bg-blue-700"
          >
            Gratis inserieren
          </a>
        </div>
      </Fragment>
    )
  }

  const OnSubmit = (e) => {

    if (!searchKeyword.search.trim()) {
      setAlert('Please fill search field!');
    } else {
      console.log("new search")
      navigate("/list/search=" + searchKeyword.search)
    }
  };


  return (
    <div>
      <Popover className="relative bg-white">
        <div className="absolute inset-0  z-30 pointer-events-none" aria-hidden="true" />
        <div className="relative z-20">
          <div className="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">

            <div>
              <a href="/" className="flex">
                <img
                  className="w-56"
                  src={Logo}
                  alt=""
                />
              </a>
            </div>


            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>

            <div className="hidden md:flex-1 md:flex md:justify-end md:items-center">
              <Popover.Group as="nav" className="flex space-x-10">
                <Popover>
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 '
                        )}
                      >
                        <span>Kategorien</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-600' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 -translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-1"
                      >
                        <Popover.Panel className=" hidden md:block absolute z-10 max-w-7xl mx-auto top-full inset-x-0 transform shadow-2xl bg-white">
                          <div className="ml-20 grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
                            {categories.map((item, index) => (
                              <a href={item.href} key={index}>
                                <button
                                  key={item.name}
                                  className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50"
                                  type='submit'
                                  onClick={(e) => OnNavigate(item.href)}
                                >
                                  <div className="flex md:h-full lg:flex-col">
                                    <div className="flex-shrink-0">
                                      <span className="inline-flex items-center justify-center h-10 w-10 rounded-md  sm:h-12 sm:w-12">
                                        <item.icon className="h-6 w-6" aria-hidden="true" />
                                      </span>
                                    </div>
                                    <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                      <div>
                                        <p className="text-base font-medium text-gray-900">{item.name}</p>
                                        <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                      </div>
                                      <p className="mt-2 text-sm font-medium text-indigo-600 lg:mt-4">
                                        Mehr Info <span aria-hidden="true">&rarr;</span>
                                      </p>
                                    </div>
                                  </div>
                                </button>
                              </a>
                            ))}
                          </div>

                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>


              </Popover.Group>
              {/* Search bar */}

              <div className="flex items-center md:ml-12 ">
                {isAuthenticated ? authLinks() : guestLinks()}
              </div>

            </div>

          </div>

        </div>



        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5 sm:pb-8">
                <div className="flex items-center justify-between">
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>

                {/** Mobile Kategorie */}

                <div className=" sm:mt-8">
                  <nav>

                    <div class="px-8 ">

                      {isAuthenticated ? authLinksMobile() : guestLinksMobile()}
                    </div>
                    <p class="border px-4"></p>
                    <h1 class="text-left text-2xl py-8">Kategorien</h1>
                    <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">

                      {categories.map((item, index) => (
                        <form key={index} id='cat_form' >
                          <button
                            key={item.name}
                            href={item.href}
                            className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50"
                            type='submit'
                            onClick={(e) => OnNavigate(item.href)}
                          >
                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md sm:h-12 sm:w-12">
                              <item.icon className="h-6 w-6" aria-hidden="true" />
                            </div>
                            <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                          </button>
                        </form>
                      ))}
                    </div>


                  </nav>
                </div>
              </div>

            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>

  )


}

Navbar.propTypes = {
  isAuthenticated: PropTypes.bool,
  logoutUser: PropTypes.func.isRequired,
  searchGig: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  username: state.auth.username,
  first_name: state.auth.first_name,
  last_name: state.auth.last_name
});


export default connect(mapStateToProps, { searchGig, logoutUser, setAlert })(Navbar);