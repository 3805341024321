import React from 'react'
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { add_favourites } from '../../actions/gigs';


function FavouriteButton(props,) {
    const navigate = useNavigate();


    const OnFavouriteButton = (e) => {


        if (!props.isAuthenticated) {
            navigate("/login")
            return
        }

        props.add_favourites(props.contentID)

    }



    if (props.sameUser) {
        return (
            <button onClick={() => OnFavouriteButton()} class=" ">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-red-500 fill-transparent fill-red-500" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                </svg>
            </button>
        )
    }

    return (
        <div>
            <div >
                <button onClick={() => OnFavouriteButton()} class="" >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-10 h-10 text-yellow-300 fill-yellow-300 ">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                    </svg>

                </button>
            </div>





        </div>
    )
}

FavouriteButton.propTypes = {
    isAuthenticated: PropTypes.bool,
    add_favourites: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { add_favourites })(FavouriteButton)