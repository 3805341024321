import React from 'react'
import ThumbnailCloudinary from '../../common/thumbnail.cloudinary.component'
import { useNavigate } from 'react-router-dom';
import LikeButtonComponent from './like.button.component';
import DislikeButtonComponent from './dislike.button.component';
import {
    LocationMarkerIcon,
    CashIcon,
    StarIcon
} from '@heroicons/react/solid'
import FavoriteButtonComponent from './favorite.button.component';
import profile from './images/profile.svg'

function Card(props) {
    const navigate = useNavigate()
    return (
        <div>
            {/* Cards*/}
            <div className="flex  flex-col space-y-4 p-4">
                {props.results?.map((content, index) => (
                    <div key={index} className="shadow-lg border rounded-xl"  >
                        {/* Card Picture*/}

                        <div className="md:h-56 md:flex flex-row gap-5 ">

                            <div className="flex justify-center md:hidden  ">
                                <ThumbnailCloudinary className="" ImagePath={content.images} width={350} height={200} />
                            </div>

                            <div className="p-4 py-4 hidden sm:block justify-center ">
                                <div>
                                    <ThumbnailCloudinary className="" ImagePath={content.images} width={200} height={200} />
                                </div>
                            </div>

                            {/* Clickable Object*/}
                            <div className="flex flex-1 flex-col  p-4 py-8">
                                <div onClick={() => navigate("../Product/" + content.id)}>
                                    <a >
                                        <h1 className="text-lg md:text-md break-words text-left font-semibold  text-gray-600" >
                                            {content.title}
                                        </h1>
                                    </a>
                                </div>
                                {/* Description*/}
                                <div className="line-clamp-3 text-gray-500 text-xs break-normal md:break-all text-left md:text-md sm:text-base overflow-hidden ">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: content.description }}                                                >
                                    </div>                                            </div>

                                {  /* Mehr info*/}
                            </div>

                            {/* Like*/}
                            <div className='bg-gray-50 border md:border-none md:text-lg py-4 flex md:flex-col justify-around '>
                                <div className="md:mt-4">
                                    <FavoriteButtonComponent contentID={content.id} />

                                </div>
                                <div className="px-4">
                                    <LikeButtonComponent noLikes={content.likes.length} contentID={content.id} likes={content.likes} userID={props.id}></LikeButtonComponent>

                                </div>
                                <div className="px-4">
                                    <DislikeButtonComponent noDislikes={content.dislikes.length} contentID={content.id} dislikes={content.dislikes} userID={props.id}></DislikeButtonComponent>
                                </div>
                            </div>
                        </div>

                        <div className="p-2 bg-gray-50">


                            <div className="grid grid-cols-3 divide-x-2 ">

                                {  /* Picture User*/}
                                <div className="text-center  hover:bg-gray-100 cursor-pointer ">

                                    <div className="md:flex items-center pt-1">
                                        <div className="flex-shrink-0 flex justify-center">
                                            <img className="w-7 h-7 rounded-full "
                                                src={profile} alt="Neil image" />
                                        </div>
                                        <div className="flex-1 min-w-0 ">
                                            <p className="text-sm font-medium text-gray-900 truncate dark:text-white md:mt-4">
                                                {content.username}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {  /*  User location*/}

                                <div className=" md:flex text-center justify-center items-center hover:bg-gray-100 cursor-pointer ">
                                    <div className=' flex justify-center  '>
                                        <LocationMarkerIcon className="h-8 w-8 text-red-300" />
                                    </div>
                                    <div className="flex-1 min-w-0">
                                        {content.city.length > 12 &&
                                            <p className=" md:mt-4 truncate ...">{content.zip} {content.city}...</p>}
                                        {content.city.length <= 12 &&
                                            <p className="md:mt-4">{content.zip} {content.city}</p>}

                                    </div>
                                </div>
                                {  /*  User Price*/}
                                <div className=" md:flex text-center justify-center items-center hover:bg-gray-100 cursor-pointer ">
                                    <div className="flex justify-center">
                                        <CashIcon className="h-8 w-8 mx-3 text-green-300" />
                                    </div>
                                    <div>
                                        <span className="text-sm font-semibold">ab</span>&nbsp; <span className="font-bold text-xl">{content.price_hour}</span>&nbsp; <span className="text-sm font-semibold">CHF</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Card