import React, { Component } from 'react'
import L from "leaflet"
var greenIcon = L.icon({
    iconUrl: 'https://img.icons8.com/color/48/000000/marker--v1.png',
    iconSize: [38, 38], // size of the icon
});



export class MapTest extends Component {

    GeoEncode(zipcode) {
        fetch('http://dev.virtualearth.net/REST/v1/Locations/CH/' + zipcode + '/?key=ApIQQg8l55-f_upDvPCRAbftZGcnAZRe0NpUrl-WEu7OBm7MhgLcYo44O4YiBhXO')
            .then(response => response.json())
            .then(data => console.log(data));
    }

    /**
    * Generates number of random geolocation points given a center and a radius.
    * @param  {Object} center A JS object with lat and lng attributes.
    * @param  {number} radius Radius in meters.
    * @param {number} count Number of points to generate.
    * @return {array} Array of Objects with lat and lng attributes.
    */
    generateRandomPoints(center, radius, count) {
        var points = [];
        for (var i = 0; i < count; i++) {
            points.push(this.generateRandomPoint(center, radius));
        }
        return points;
    }


    /**
    * Generates number of random geolocation points given a center and a radius.
    * Reference URL: http://goo.gl/KWcPE.
    * @param  {Object} center A JS object with lat and lng attributes.
    * @param  {number} radius Radius in meters.
    * @return {Object} The generated random points as JS object with lat and lng attributes.
    */
    generateRandomPoint(center, radius) {
        var x0 = center.lng;
        var y0 = center.lat;
        // Convert Radius from meters to degrees.
        var rd = radius / 111300;

        var u = Math.random();
        var v = Math.random();

        var w = rd * Math.sqrt(u);
        var t = 2 * Math.PI * v;
        var x = w * Math.cos(t);
        var y = w * Math.sin(t);

        var xp = x / Math.cos(y0);

        // Resulting point.
        return { 'lat': y + y0, 'lng': xp + x0 };
    }


    componentDidMount() {
        // create map

        if (this.props.listing) {

            let lats = [];
            let lons = [];

            for (let i = 0; i < this.props.content.length; i++) {
                lats[i] = parseFloat(this.props.content[i].lat)
                lons[i] = parseFloat(this.props.content[i].lon)


            }

            let sumlats = 0;
            let sumlons = 0;

            lats.forEach(item => {
                sumlats += item;
            })

            lons.forEach(item => {
                sumlons += item;
            })

            var CenterPoint = [sumlats / lats.length, sumlons / lons.length]

            if (CenterPoint.includes(NaN)) {
                CenterPoint = [47.013454215703874, 8.512182807068838]
            }



            this.map = L.map("map", {
                center: CenterPoint,
                zoom: 9,
                layers: [
                    L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
                        attribution:
                            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    })
                ]
            });
            // add marker
            for (let i = 0; i < this.props.content.length; i++) {
                var lat = this.props.content[i].lat
                var lon = this.props.content[i].lon

                var result = this.generateRandomPoints({'lat':parseFloat(lat), 'lng':parseFloat(lon)}, 1000, 1)

                lat = result[0].lat
                lon = result[0].lng

                var markerPos = [lat, lon]
                var photoImg = ''
                if (this.props.content[i].images.length > 0) {
                    var photoImg = '<img src=' + this.props.content[i].images[0].image + ' height="150px" width="150px"/>';
                }
                var title = '<a href=../Product/' + this.props.content[i].id + '>' + this.props.content[i].title + '<br />' + this.props.content[i].zip + ' ' + this.props.content[i].city

                var popup = L.popup()
                    .setContent(

                        title + photoImg + '</a>'

                    )

                L.marker(markerPos, { icon: greenIcon }).addTo(this.map).bindPopup(popup);
            }
        }

        else {
            var CenterPoint = [this.props.content.lat, this.props.content.lon]


            if (CenterPoint.includes(NaN)) {
                CenterPoint = [47.013454215703874, 8.512182807068838]
            }

            this.map = L.map("map", {
                center: CenterPoint,
                zoom: 15,
                layers: [
                    L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
                        attribution:
                            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    })
                ]
            });

            L.marker(CenterPoint, { icon: greenIcon }).addTo(this.map);
        }

        //this.marker = L.marker(this.props.markerPosition, { icon: greenIcon }).addTo(this.map).bindPopup(popup);
        //this.marker = L.marker(this.props.markerPosition2, { icon: greenIcon }).addTo(this.map).bindPopup(popup);
    }

    componentDidUpdate({ markerPosition }) {
        // check if position has changed
        if (this.props.markerPosition !== markerPosition) {
            this.marker.setLatLng(this.props.markerPosition);
        }
    }


    render() {
        return <div id="map" style={this.props.style} />;
    }

}

export default MapTest