import React from 'react'
import { useNavigate } from 'react-router-dom';
import Profile from './profil.component';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'


function ProfilWrapper({ isAuthenticated, setAlert, id }) {
    const navigate = useNavigate();

    if(!isAuthenticated){
      navigate("/Login")
    }

  return <Profile navigate={navigate}{...{id, setAlert, isAuthenticated}} />;
}

ProfilWrapper.propTypes = {
  isAuthenticated: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
  id: PropTypes.number
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  username: state.auth.username,
  first_name: state.auth.first_name,
  last_name: state.auth.last_name,
  id: state.auth.id
});

export default connect(mapStateToProps) (ProfilWrapper)