import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { Footer } from '../Footer/footer.component';
import Verifiedimage from './Images/mailverified.jpg'
function VerifiedMail() {

    const navigate = useNavigate();


    useEffect(() => {
        setTimeout(() => {
            navigate('/')
        }, 3000)
    }, [])
    return <div>
        <div class="relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12 bg-white">
            <div class="max-w-xl px-5 text-center">
                <img src={Verifiedimage}  class=" mx-auto h-72 w-72" />
                <h2 class="mb-2 text-[42px] font-bold text-zinc-800">Konto bestätigt</h2>
                <h2 class="mb-2 text-[42px] font-bold text-zinc-800">Bitte loggen sie sich ein !</h2>
                <p class="mb-2 text-lg text-zinc-500">Sie werden in 3 Sekunden weitergeleitet</p>
                <p class="mb-2 text-lg text-zinc-500">oder klicken sie <a href='/' class="font-medium text-indigo-500">hier</a></p>
            </div>
        </div>
        <Footer></Footer>
    </div>

}

export default VerifiedMail