import React from 'react'
import { activateGig, deactivateGig } from '../../actions/gigs';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";


function ActivateButton(props) {
    const navigate = useNavigate();

    const OnActivate = (e) => {
        if (!props.isAuthenticated) {
            navigate("/login")
            return
        }

        let data = { status: true }

        props.activateGig(data, props.id)


    }

    const OnDeactivate = (e) => {
        if (!props.isAuthenticated) {
            navigate("/login")
            return
        }

        let data = { status: false }

        props.activateGig(data, props.id)
    }



    if (props.status) {
        return (
            <button
                onClick={() => OnDeactivate()}
                class=" grow py-2 bg-amber-500 outline-none text-white  shadow-lg md:text-lg	active:shadow-none active:scale-95 hover:bg-amber-600 focus:bg-indigo-600 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 disabled:bg-gray-400/80 disabled:shadow-none disabled:cursor-not-allowed transition-colors duration-200">
                <div class="flex justify-center space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <span>Deaktivieren</span>

                </div>
            </button>
        )
    }
    else {
        return (
            <button
            onClick={() => OnActivate()}
                class=" grow py-2 bg-green-500 outline-none text-white  shadow-lg md:text-lg	active:shadow-none active:scale-95 hover:bg-amber-600 focus:bg-indigo-600 focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 disabled:bg-gray-400/80 disabled:shadow-none disabled:cursor-not-allowed transition-colors duration-200">
                <div class="flex justify-center space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                    <span>Aktivieren</span>

                </div>
            </button>
        )
    }

}


ActivateButton.propTypes = {
    isAuthenticated: PropTypes.bool,
    activateGig: PropTypes.func.isRequired,
    deactivateGig: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { activateGig, deactivateGig })(ActivateButton)