import api from "./api";
import TokenService from "./token.service";


class AuthService {

  async login(user) {
    var bodyFormData = new FormData();
    bodyFormData.append('email', user.email);
    bodyFormData.append('password', user.password);

    const response = await api
      .post("/auth/login/", bodyFormData);
      console.log(response)
    if (response.data.access_token) {

      TokenService.setLocalAccessToken(response.data.access_token);
      TokenService.setLocalRefreshToken(response.data.refresh_token);
    }
    return response;
  }

  async google(token){

    const config = {
      headers: {
        accept: 'application/json',
      },
      
    };

    const json = JSON.stringify({ id_token: token });

    var bodyFormData = new FormData();
    bodyFormData.append('id_token', token);

    const response = await api.post(`/google/`, bodyFormData );
    
  }


  async googleAuthenticate(state, code) {
    if (state && code && !localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type':'application/x-www-form-urlencoded'
        }
    }
      const details = {
        'state': state,
        'code': code
      }

      var data = new URLSearchParams();
      data.append('state', state);
      data.append('code', code);

      const bodyFormData = Object.keys(details).map(key=> encodeURIComponent(key)+'='+encodeURIComponent(details[key])).join('&')

      //var bodyFormData = new FormData();
      //bodyFormData.append('state', state);
      //bodyFormData.append('code', code);
      
      console.log("Formbody; " + bodyFormData)

      const response = await api.post(`/v1/o/google-oauth2/`, data );
      console.log(response.data)
      if (response.data.access) {
        TokenService.setLocalAccessToken(response.data.access);
        TokenService.setLocalRefreshToken(response.data.refresh);
      }
      return response.data;
    }
  }

  async googleSignin(token){
    var bodyFormData = new FormData();
    bodyFormData.append('access_token', token);

    const response = await api
      .post("/auth/google/", bodyFormData);
      console.log(response)

      if (response.data.access_token) {
        TokenService.setLocalAccessToken(response.data.access_token);
        TokenService.setLocalRefreshToken(response.data.refresh_token);
      }

    return response;
  }

  async facebookLogin(token){
    var bodyFormData = new FormData();
    bodyFormData.append('access_token', token);

    const response = await api
      .post("/auth/facebook/", bodyFormData);
      console.log("HEREEE")
      console.log(response)

    if (response.data.access_token) {
      TokenService.setLocalAccessToken(response.data.access_token);
      TokenService.setLocalRefreshToken(response.data.refresh_token);
    }
    return response;
  }

  logout() {
    console.log("User logged out")
    TokenService.removeLocalAccessToken();
    TokenService.removeLocalRefreshToken();
  }

  async register(user) {
    var bodyFormData = new FormData();
    bodyFormData.append('username', user.username);
    bodyFormData.append('email', user.email);
    bodyFormData.append('password1', user.password);
    bodyFormData.append('password2', user.password2);
    console.log(bodyFormData)

    const response = await api.post("/auth/register/", bodyFormData);


    if (response.data.access) {
      TokenService.setLocalAccessToken(response.data.access);
      TokenService.setLocalRefreshToken(response.data.refresh);
    }
    return response.data;
  }

  async loadUser() {
    const response = await api.get(`/get_user/`);
    return response.data
  }

  async resetPassword(email) {
    var bodyFormData = new FormData();
    bodyFormData.append('email', email)

    const response = await api.post("auth/password/reset/", bodyFormData)
    return response.data
  }

  async setNewPassword(data) {
    var bodyFormData = new FormData();
    console.log(data)
    bodyFormData.append('uid', data.uid)
    bodyFormData.append('token', data.token)
    bodyFormData.append('new_password1', data.new_password1)
    bodyFormData.append('new_password2', data.new_password2)

    const response = await api.post("auth/password/reset/confirm/", bodyFormData)
    return response.data
  }
}

export default new AuthService();