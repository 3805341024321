import React from 'react'
import { useNavigate } from 'react-router-dom';
import ThumbnailCloudinary from '../../common/thumbnail.cloudinary.component';
import {
    LocationMarkerIcon,
    CashIcon,
    StarIcon
} from '@heroicons/react/solid'
import user from './images/user.svg'

function BlackCard(props) {
    const navigate = useNavigate();

    return (
        <div>
            <div className="flex shadow-2xl justify-between m-6 border" onClick={() => navigate("../Product/" + props.content.id)}>
                <div className="flex flex-col h-full max-w-lg mx-auto rounded-lg">
                    <div className="md:hidden  bg-gray-100" >
                        <ThumbnailCloudinary className="" ImagePath={props.content.images} width={350} height={200} />
                    </div>
                    <div className="hidden sm:block ">
                        <ThumbnailCloudinary className="" ImagePath={props.content.images} width={400} height={250} />
                    </div>
                    <div className="flex justify-between -mt-4 px-4">
                        <span
                            className="inline-block bg-yellow-300 ring-gray-800 rounded-lg text-xs font-medium tracking-wide text-gray-900 p-2"
                        >{props.content.category}</span
                        >
                        <span className="flex h-min space-x-1 items-center rounded-lg text-white bg-red-500 py-1 p-2 text-xs font-medium">
                            <div>
                                {props.content.city}
                            </div>
                        </span>
                    </div>
                    <div className="py-2 px-4">
                        <h1
                            className="text-xl font-medium leading-6 tracking-wide line-clamp-1  hover:text-blue-500 cursor-pointer">
                            {props.content.title}
                        </h1>
                    </div>

                    <div className="flex flex-row items-end h-full w-full px-4 mt-4">
                        <div className="flex border-t border-gray-700 w-full py-4">
                            <div
                                className="flex items-center space-x-3 border-r border-gray-700 w-full"
                            >
                                <img
                                    className="object-cover w-5 h-5 border-2 border-white rounded-full"
                                    src={user}
                                    alt="profile users"
                                    loading="lazy"
                                />
                                
                                    <div className="text-xs font-semibold tracking-wide shrink truncate ...">
                                        {props.content.username}
                                    </div>

                               
                            </div>
                            <div className="flex items-center flex-shrink-0 px-2">
                                <div className="flex text-xs items-center space-x-1 font-bold">
                                ab {props.content.price_hour}.- CHF
                                    
                                </div>
   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlackCard