import React from 'react'
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { add_favourites } from '../../actions/gigs';

function findById(source, id) {
    for (var i = 0; i < source.length; i++) {

        if (source[i].user === id) {
            return true;
        }
    }
    return false;
}

function FavouriteButton(props,) {
    const navigate = useNavigate();

    const OnFavouriteButton = (e) => {

        if (!props.isAuthenticated) {
            navigate("/login")
            return
        }

        props.add_favourites(props.contentID)
    }

    return (

        <div class="">
            <button onClick={() => OnFavouriteButton()} class="px-4">
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="fill-amber-500 w-8 h-8 text-amber-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                </svg>
            </button>
        </div>

    )


}

FavouriteButton.propTypes = {
    isAuthenticated: PropTypes.bool,
    add_favourites: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { add_favourites })(FavouriteButton)