
import PropTypes from 'prop-types';

import { StarIcon } from '@heroicons/react/solid'
import { useEffect } from 'react'
import React, { useState } from 'react'
import GigService from '../../services/gig.service';
import { latLng } from 'leaflet';
import BlackCard from './card.component';
import { get_latest_gigs } from '../../actions/gigs';
import { connect } from 'react-redux';
const products = [
  {
    id: 1,
    name: 'Organize Basic Set (Walnut)',
    price: '$149',
    rating: 5,
    reviewCount: 38,
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-01.jpg',
    imageAlt: 'TODO',
    href: '#',
  },
  {
    id: 2,
    name: 'Organize Pen Holder',
    price: '$15',
    rating: 5,
    reviewCount: 18,
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-02.jpg',
    imageAlt: 'TODO',
    href: '#',
  },
  {
    id: 3,
    name: 'Organize Sticky Note Holder',
    price: '$15',
    rating: 5,
    reviewCount: 14,
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-03.jpg',
    imageAlt: 'TODO',
    href: '#',
  },
  {
    id: 4,
    name: 'Organize Phone Holder',
    price: '$15',
    rating: 4,
    reviewCount: 21,
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-04.jpg',
    imageAlt: 'TODO',
    href: '#',
  },

  {
    id: 5,
    name: 'Organize Phone Holder',
    price: '$15',
    rating: 4,
    reviewCount: 21,
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-04.jpg',
    imageAlt: 'TODO',
    href: '#',
  }
  ,
  {
    id: 6,
    name: 'Organize Phone Holder',
    price: '$15',
    rating: 4,
    reviewCount: 21,
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-04.jpg',
    imageAlt: 'TODO',
    href: '#',
  },
  ,
  {
    id: 7,
    name: 'Organize Phone Holder',
    price: '$15',
    rating: 4,
    reviewCount: 21,
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-05-image-card-04.jpg',
    imageAlt: 'TODO',
    href: '#',
  },
  // More products...
]


function LatestContent() {

  const [content, setContent] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    GigService.getLatestContent().then(
      response => {
        setContent({
          content: response.data,
        });
        setLoading({ loading: false })
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });


      }
    );
  }, [])




  if(loading==true){
    return
  }


  return (
    <div className="">
      <div className="max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8">
        <p className="text-3xl py-8 font-adineue">Neueste Mietinserate</p>

        <div className="-mx-px py-8 grid grid-cols-1 sm:mx-0 md:grid-cols-3 lg:grid-cols-4">
          {content.content.map((item, index) => (
            <div key={index}>
                <BlackCard content={item}></BlackCard>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}


LatestContent.propTypes = {
  get_latest_gigs: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({

});


export default connect(mapStateToProps, { get_latest_gigs })(LatestContent);
