import { combineReducers } from 'redux';

// IMPORT REDUCERS
import auth from './auth.js';
import alert from './alert';
import gig from './gig';

export default combineReducers({
  auth,
  alert,
  gig
});