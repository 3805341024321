import React, { Component } from 'react'
import Navbar from '../Nav-Bar/nav-bar.component'
import FavoritesCard from '../Favorites/favorites.cards.component'
// REDUX
import GigService from '../../services/gig.service';
import EventBus from '../../common/EventBus';
import { Footer } from '../Footer/footer.component';

export default class Favorites extends Component {
    constructor(props) {
        super(props);
        const content = []
        const user = []
        this.state = { content, user }
    }

    componentDidMount() {
        GigService.getFavorites().then(

            response => {
                this.setState({
                    content: response.data
                });
                EventBus.dispatch("ALLGIGS_LOADED")
            },


            error => {
                this.setState({
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                });

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("ALLGIGS_FAILED");
                }
            }
        );
    }

    render() {
        return (
            <div>
                <div>
                    <Navbar></Navbar>
                </div>
                <div class="min-h-screen max-h-screen  max-w-7xl mx-auto">

                    <div class="w-full mb-12 lg:mb-0 py-8">
                        <h1 class="sm:text-4xl text-5xl font-medium font-bold title-font mb-8 text-gray-900">Meine Favoriten</h1>
                        <div class="h-1 bg-indigo-500 "></div>
                    </div>
                    {/* Cards*/}
                    <div class="w-full">
                        <FavoritesCard results={this.state.content}></FavoritesCard>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        )
    }
}
