import React, { Component } from 'react'
import Navbar from '../Nav-Bar/nav-bar.component'
import Profilecard from './profile.card.component'
// REDUX
import GigService from '../../services/gig.service';
import UserService from '../../services/user.service';
import EventBus from '../../common/EventBus';
import { Footer } from '../Footer/footer.component';
import Searchbar from './searchbar.component';
import profile from './images/profile.svg'

export default class Profile extends Component {
    constructor(props) {
        super(props);
        const content = []
        const user = []
        this.state = { content, user }
    }

    componentDidMount() {
        GigService.getPrivateContent().then(

            response => {
                this.setState({
                    content: response.data
                });
                EventBus.dispatch("ALLGIGS_LOADED")
            },


            error => {
                this.setState({
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                });

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("ALLGIGS_FAILED");
                }
            }
        );

        UserService.getUserProfile().then(

            response => {
                this.setState({
                    user: response.data
                });
                EventBus.dispatch("ALLGIGS_LOADED")
            },

            error => {
                this.setState({
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                });

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("ALLGIGS_FAILED");
                }
            }
        );

    }




    render() {
        return (
            <div>

                <div>
                    <Navbar></Navbar>
                </div>

                <div>
                    <Searchbar></Searchbar>
                </div>
                <div class="min-h-screen max-h-screen  max-w-7xl mx-auto">

                    <div class="w-full mb-12 lg:mb-0 py-8">
                        <h1 class="sm:text-4xl text-5xl font-medium font-bold title-font mb-8 text-gray-900">Meine Inserate</h1>
                        <div class="h-1 bg-indigo-500 "></div>
                    </div>
                    {/* Cards*/}
                    <div class="w-full">
                        <Profilecard results={this.state.content}></Profilecard>
                    </div>
                    <Footer></Footer>
                </div>
            </div>


        )
    }
}
