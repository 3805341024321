import React from 'react'
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from "@cloudinary/url-gen";
import errorLogo from '../Images/Asset/noimage.png'
import { useState, Fragment } from 'react'
import { Dialog,Transition } from '@headlessui/react'

// Import any actions required for transformations.
import { fill, limitFill, limitFit } from "@cloudinary/url-gen/actions/resize";


function ImageFound(props) {


    if (props.boolerror === false) {
        return <AdvancedImage cldImg={props.myImage} />

    } else {
        return (<img src={errorLogo} width={props.width} height={props.height} />)
    }
}


function CloudinaryWrapper(props) {

    const [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
        props.updateCurrentPicture(props.image)
    }


    let ImageID
    let boolerror = false



    let idx = 0;

    ImageID = props.image
    boolerror = false
    idx = ImageID.indexOf('upload');
    ImageID = ImageID.substring(idx + 7, ImageID.length)


    // 2. Set your cloud name
    //========================

    // Create a Cloudinary instance and set your cloud name.
    const cld = new Cloudinary({
        cloud: {
            cloudName: 'nebula-cloudinary'
        }
    });


    // 3. Get your image
    //===================

    // Instantiate a CloudinaryImage object for the image with the public ID, 'docs/models'.
    const myImage = cld.image(ImageID);



    // 4. Transform your image
    //=========================

    // Resize to 400 x 400 pixels using the 'fill' crop mode.
    myImage.resize(limitFit().width(props.width).height(props.height));



    // 5. Deliver your image
    // =========================

    // Render the image in a React component.
    return (
        <div>
            <button onClick={openModal} data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
                <ImageFound boolerror={boolerror} myImage={myImage} width={props.width} height={props.height} />
            </button>

            

        </div >

    )
}

export default CloudinaryWrapper