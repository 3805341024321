export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const GOOGLE_AUTH_FIRST = 'GOOGLE_AUTH_FIRST'
export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS'
export const GOOGLE_AUTH_FAIL = 'GOOGLE_AUTH_FAIL'
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const LEADS_LOADING = 'LEADS_LOADING';
export const GET_LEADS = 'GET_LEADS';
export const GET_LEAD = 'GET_LEAD';
export const CREATE_GIG = 'CREATE_LEAD';
export const UPDATE_GIG = 'UPDATE_GIG';
export const GIG_ACTIVATE = 'GIG_ACTIVATE';
export const GIG_DEACTIVATE = 'GIG_DEACTIVATE';
export const GIG_ERRORS = 'GIG_ERRORS';
export const CREATE_ALERT = 'CREATE_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const GIG_LOADING = 'GIG_LOADING';
export const GIG_LOADED = 'GIG_LOADED';
export const GIG_ERROR = 'GIG_ERROR';
export const GIG_LIKE = 'GIG_LIKE';
export const GIG_DISLIKE = 'GIG_DISLIKE';
export const ALLGIGS_LOADING = 'ALLGIGS_LOADING';
export const ALLGIGS_LOADED = 'ALLGIGS_LOADED';
export const ALLGIGS_ERROR = 'ALLGIGS_ERROR';
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS"
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_SUCCESS"
export const GET_LATEST_GIGS = 'GET_LATEST_GIGS';
export const GIG_DELETE = 'ALLGIGS_LOADED';
export const API_REQUEST = "API_REQUEST";
export const API_SUCCESS = "API_SUCCESS";
export const API_ERROR = "API_ERROR";

