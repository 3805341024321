/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Disclosure, Menu, Popover, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { ChevronDoubleRightIcon } from '@heroicons/react/solid'
import { useNavigate } from 'react-router-dom';
import {
  MusicNoteIcon,
  BookOpenIcon,
  AcademicCapIcon,
  DesktopComputerIcon,
  CogIcon,
  TruckIcon,
  PencilIcon,
  HomeIcon,
  StopIcon,
  VideoCameraIcon,
  VariableIcon
} from '@heroicons/react/outline'

const sortOptions = [

  { value: 'new', label: 'Neueste', id: 'sort', name: 'Sortieren', checked: false, },
  { value: 'best', label: 'Beliebteste', id: 'sort', name: 'Sortieren', checked: false, },
  { value: 'costly', label: 'Teuerste', id: 'sort', name: 'Sortieren', checked: false, },
  { value: 'cheap', label: 'Günstigste', id: 'sort', name: 'Sortieren', checked: false, },


]
const filters = [
  {
    id: 'category',
    name: 'Kategorien',
    options: [

      { value: 'Fahrzeuge', label: 'Fahrzeuge', checked: false, id: 'category', name: 'Kategorie', icon: { TruckIcon } },
      { value: 'Kleider', label: 'Kleider', checked: false, id: 'category', name: 'Kategorie', icon: '' },
      { value: 'Immobilien', label: 'Immobilien', checked: false, id: 'category', name: 'Kategorie', icon: '' },
      { value: 'Kleidung', label: 'Kleidung', checked: false, id: 'category', name: 'Kategorie', icon: '' },
      { value: 'Audio', label: 'Audio', checked: false, id: 'category', name: 'Kategorie', icon: '' },
      { value: 'Möbel', label: 'Möbel', checked: false, id: 'category', name: 'Kategorie', icon: '' },
      { value: 'TV', label: 'TV', checked: false, id: 'category', name: 'Kategorie', icon: '' },
      { value: 'Handwerk', label: 'Handwerk', checked: false, id: 'category', name: 'Kategorie', icon: '' },
      { value: 'Sport', label: 'Sport', checked: false, id: 'category', name: 'Kategorie', icon: '' },
      { value: 'Computer', label: 'Computer', checked: false, id: 'category', name: 'Kategorie', icon: '' },
      { value: 'Bücher', label: 'Bücher', checked: false, id: 'category', name: 'Kategorie', icon: '' },
      { value: 'Sonstiges', label: 'Sonstiges', checked: false, id: 'category', name: 'Kategorie', icon: '' },
      { value: 'Diensleistungen', label: 'Diensleistungen', checked: false, id: 'category', name: 'Kategorie', icon: '' },
    ],
  },
  {
    id: 'canton',
    name: 'Kanton',
    options: [
      { value: 'Basel', label: 'Basel', checked: false, id: 'canton', name: "Kanton", icon: " https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/bs.svg" },
      { value: 'Zürich', label: 'Zürich', checked: false, id: 'canton', name: "Kanton", icon: " https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/zh.svg" },
      { value: 'Bern', label: 'Bern', checked: false, id: 'canton', name: "Kanton", icon: " https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/be.svg" },
      { value: 'Luzern', label: 'Luzern', checked: false, id: 'canton', name: "Kanton", icon: "https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/lu.svg " },
      { value: 'Uri', label: 'Uri', checked: false, id: 'canton', name: "Kanton", icon: "https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/ur.svg " },
      { value: 'Schwyz', label: 'Schwyz', checked: false, id: 'canton', name: "Kanton", icon: " https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/sz.svg" },
      { value: 'Obwalden', label: 'Obwalden', checked: false, id: 'canton', name: "Kanton", icon: "https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/ow.svg " },
      { value: 'Nidwalden', label: 'Nidwalden', checked: false, id: 'canton', name: "Kanton", icon: " https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/nw.svg" },
      { value: 'Glarus', label: 'Glarus', checked: false, id: 'canton', name: "Kanton", icon: " https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/gl.svg" },
      { value: 'Zug', label: 'Zug', checked: false, id: 'canton', name: "Kanton", icon: "https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/zg.svg " },
      { value: 'Freiburg', label: 'Freiburg', checked: false, id: 'canton', name: "Kanton", icon: " https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/fr.svg" },
      { value: 'Solothurn', label: 'Solothurn', checked: false, id: 'canton', name: "Kanton", icon: "https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/so.svg " },
      { value: 'Basel-Landschaft', label: 'Basel-Landschaft', checked: false, id: 'canton', name: "Kanton", icon: "https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/bl.svg" },
      { value: 'Schaffhausen', label: 'Schaffhausen', checked: false, id: 'canton', name: "Kanton", icon: " https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/sh.svg" },
      { value: 'Appenzell Innerrhoden', label: 'Appenzell A.Rh', checked: false, id: 'canton', name: "Kanton", icon: " https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/ar.svg" },
      { value: 'Appenzell Ausserrhoden', label: 'Appenzell I.Rh', checked: false, id: 'canton', name: "Kanton", icon: " https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/ai.svg" },
      { value: 'Sankt Gallen', label: 'St.Gallen', checked: false, id: 'canton', name: "Kanton", icon: "https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/sg.svg " },
      { value: 'Graubünden', label: 'Graubünden', checked: false, id: 'canton', name: "Kanton", icon: " https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/gr.svg" },
      { value: 'Aargau', label: 'Aargau', checked: false, id: 'canton', name: "Kanton", icon: "https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/ag.svg " },
      { value: 'Thurgau', label: 'Thurgau', checked: false, id: 'canton', name: "Kanton", icon: "https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/tg.svg " },
      { value: 'Tessin', label: 'Tessin', checked: false, id: 'canton', name: "Kanton", icon: "https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/ti.svg " },
      { value: 'Waadt', label: 'Waadt', checked: false, id: 'canton', name: "Kanton", icon: "https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/vd.svg " },
      { value: 'Wallis', label: 'Wallis', checked: false, id: 'canton', name: "Kanton", icon: " https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/vs.svg" },
      { value: 'Neuenburg', label: 'Neuenburg', checked: false, id: 'canton', name: "Kanton", icon: " https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/ne.svg" },
      { value: 'Genf', label: 'Genf', checked: false, id: 'canton', name: "Kanton", icon: " https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/ge.svg" },
      { value: 'Jura', label: 'Jura', checked: false, id: 'canton', name: "Kanton", icon: "https://raw.githubusercontent.com/ylerjen/swiss-flags/master/cantons/ju.svg " },
    ],
  },

  {
    id: 'price_min',
    name: 'Preis ab',
    options: [
      { value: '10', label: '10 CHF', checked: false, id: 'price_min', name: "Preis ab" },
      { value: '20', label: '20 CHF', checked: false, id: 'price_min', name: "Preis ab" },
      { value: '500', label: '50 CHF', checked: false, id: 'price_min', name: "Preis ab" },
      { value: '100', label: '100 CHF', checked: false, id: 'price_min', name: "Preis ab" },
      { value: '200', label: '100 CHF', checked: false, id: 'price_min', name: "Preis ab" },
    ],

  },

  {
    id: 'price_max',
    name: 'Preis bis',
    options: [
      { value: '10', label: '10 CHF', checked: false, id: 'price_max', name: "Preis bis" },
      { value: '20', label: '20 CHF', checked: false, id: 'price_max', name: "Preis bis" },
      { value: '500', label: '50 CHF', checked: false, id: 'price_max', name: "Preis bis" },
      { value: '100', label: '100 CHF', checked: false, id: 'price_max', name: "Preis bis" },
      { value: '200', label: '100 CHF', checked: false, id: 'price_max', name: "Preis bis" },
    ],
  }
]
const activeFilters = []

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}





export default function NewFilter(props) {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate();
  const [Search, setSearch] = useState('');
  const [category, setCategory] = useState('');
  const [price_min, setPrice_Min] = useState('');
  const [price_max, setPrice_Max] = useState('');
  const [pageNo, setPageNo] = useState('');
  const [location, setLocation] = useState('');
  const [sort, setSort] = useState('');
  const [init, setInit] = useState(0)
  const [pm, setpm] = useState({ Search: "", pageNo: "", category: "", canton: "", price_max: "", price_min: "", sort: "" })



  useEffect(() => {

    const paramsWithKey = props.data.split('&')

    let s = ""
    let pag = ""
    let cat = ""
    let loc = ""
    let price_min = ""
    let price_max = ""
    let sort = ""


    for (var i = 0; i < paramsWithKey.length; i++) {

      if (paramsWithKey[i].includes('search=')) {
        setSearch(paramsWithKey[i] + "&")
        s = paramsWithKey[i] + "&"
      }
      if (paramsWithKey[i].includes('page=')) {
        setPageNo(paramsWithKey[i] + "&")

        pag = paramsWithKey[i] + "&"

      }

      if (paramsWithKey[i].search('category') > -1) {
        cat = paramsWithKey[i].split('=')[1]
        setCategory(cat + "&")

        for (let i = 0; i < filters[0].options.length; i++) {
          if (cat == filters[0].options[i].value) {
            addToNewActiveFilters({ value: filters[0].options[i].value, label: filters[0].options[i].label, id: filters[0].options[i].id, name: filters[0].options[i].name })
          }
        }
      }

      if (paramsWithKey[i].search('canton') > -1) {
        loc = paramsWithKey[i].split('=')[1]
        setLocation(loc + "&")

        for (let i = 0; i < filters[1].options.length; i++) {
          if (loc == filters[1].options[i].value) {
            addToNewActiveFilters({ value: filters[1].options[i].value, label: filters[1].options[i].label, id: filters[1].options[i].id, name: filters[1].options[i].name })
          }
        }
      }

      if (paramsWithKey[i].search('price_max') > -1) {
        price_max = paramsWithKey[i].split('=')[1]
        setPrice_Max(price_max + "&")

        for (let i = 0; i < filters[2].options.length; i++) {
          if (price_max == filters[2].options[i].value) {
            addToNewActiveFilters({ value: filters[2].options[i].value, label: filters[2].options[i].label, id: filters[2].options[i].id, name: filters[2].options[i].name })
          }
        }
      }

      if (paramsWithKey[i].search('price_min') > -1) {
        price_min = paramsWithKey[i].split('=')[1]
        setPrice_Min(price_min + "&")

        for (let i = 0; i < filters[3].options.length; i++) {
          if (price_min == filters[3].options[i].value) {
            addToNewActiveFilters({ value: filters[3].options[i].value, label: filters[3].options[i].label, id: filters[3].options[i].id, name: filters[3].options[i].name })
          }
        }
      }

      if (paramsWithKey[i].search('sort') > -1) {
        sort = paramsWithKey[i].split('=')[1]
        setSort(sort + "&")


        for (let i = 0; i < sortOptions.length; i++) {
          if (sort == sortOptions[i].value) {
            addToNewActiveFilters({ value: sortOptions[i].value, label: sortOptions[i].label, id: sortOptions[i].id, name: sortOptions[i].name })
          }
        }
      }
    }

    setpm({ Search: s, pageNo: pag, category: cat, canton: loc, price_max: price_max, price_min: price_min, sort: sort })


  },
    [])



  const addToNewActiveFilters = (options) => {

    for (let i = 0; i < activeFilters.length; i++) {
      if (activeFilters[i].value != options.value && activeFilters[i].id === options.id) {
        removeFromActiveFilters(activeFilters[i].value)
      }
    }

    for (let i = 0; i < activeFilters.length; i++) {
      if (activeFilters[i].value == options.value) {
        return
      }
    }

    for (let i = 0; i < filters.length; i++) {
      for (let x = 0; x < filters[i].options.length; x++) {
        if (filters[i].options[x].value === options.value) {
          filters[i].options[x].checked = true
        }
      }
    }



    activeFilters.push({ value: options.value, label: options.label, id: options.id, name: options.name })

    buildURLfromFilter()
  }


  const removeFromActiveFilters = (value) => {

    for (let i = 0; i < filters.length; i++) {
      for (let x = 0; x < filters[i].options.length; x++) {
        if (filters[i].options[x].value === value) {
          filters[i].options[x].checked = false
        }
      }
    }

    for (let i = 0; i < activeFilters.length; i++) {
      if (activeFilters[i].value === value) {
        activeFilters.splice(i, 1)
      }
    }

    buildURLfromFilter()
  }

  const buildURLfromFilter = () => {
    let filterProperties = Search

    console.log(pm)

    for (let i = 0; i < activeFilters.length; i++) {
      filterProperties = filterProperties + activeFilters[i].id + "=" + activeFilters[i].value + "&"
    }

    props.setParams(filterProperties)
    props.HandleFilter(filterProperties)
  }


  return (
    <div className="bg-white">
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 sm:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 flex items-center justify-between">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4">
                {filters.map((section) => (
                  <Disclosure as="div" key={section.name} className="border-t border-gray-200 px-4 py-6">
                    {({ open }) => (
                      <>
                        <h3 className="-mx-2 -my-3 flow-root">
                          <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400">
                            <span className="font-medium text-gray-900">{section.name}</span>
                            <span className="ml-6 flex items-center">
                              <ChevronDownIcon
                                className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-6">
                            {section.options.map((option, optionIdx) => (
                              <div key={option.value} className="flex items-center">
                                <input
                                  id={`filter-mobile-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.value}
                                  type="radio"
                                  defaultChecked={option.checked}
                                  className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                                  onClick={() => addToNewActiveFilters(option)}
                                />
                                {
                                  /*
                                    <div class="px-4">
                                                                  <img class="h-8 w-8" src={option.icon} alt="" />
                                                                </div>
                                   */
                                }

                                <label
                                  htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                  className="ml-3 text-base text-gray-500"
                                >
                                  {option.label}
                                </label>

                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </form>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>



      {/* Filters */}
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>

        <div className="max-w-7xl mx-auto relative z-10 bg-white border-b border-gray-900 pb-4">
          <div className=" px-4 flex items-center justify-between sm:px-6 lg:px-8">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-900 hover:text-gray-800">
                  Sortieren
                  <ChevronDownIcon
                    className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-900 group-hover:text-gray-800"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option) => (
                      <Menu.Item key={option.name}>
                        {({ active }) => (
                          <a
                            href={option.href}
                            className={classNames(
                              option.current ? 'font-medium text-gray-900' : 'text-gray-900',
                              active ? 'bg-amber-400' : '',
                              'block px-4 py-2 text-sm'
                            )}
                            onClick={() => addToNewActiveFilters(option)}
                          >
                            {option.label}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <button className="inline-block flex text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
            onClick={() => setOpen(true)}
            >
            Filters
                  <ChevronRightIcon
                    className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-900 group-hover:text-gray-800"
                    aria-hidden="true"
                  />
                </button>

  



            <div className="hidden sm:block">
              <div className="flow-root">
                <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-900">
                  {filters.map((section, sectionIdx) => (
                    <Popover key={section.name} className="px-4 relative inline-block text-left">
                      <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-900 hover:text-gray-800">
                        <span>{section.name}</span>

                        <ChevronDownIcon
                          className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-900 group-hover:text-gray-800"
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <form className="space-y-4">
                            {section.options.map((option, optionIdx) => (
                              <div key={option.value} className="flex items-center">
                                <input
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.value}
                                  type="radio"
                                  defaultChecked={option.checked}
                                  className="h-4 w-4 border-gray-900 rounded text-indigo-600 focus:ring-indigo-500"
                                  onClick={() => addToNewActiveFilters(option)}
                                />
                                <label
                                  htmlFor={`filter-${section.id}-${optionIdx}`}
                                  className="ml-3 pr-6 text-sm font-medium  text-gray-900 whitespace-nowrap"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </form>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  ))}
                </Popover.Group>
              </div>
            </div>
          </div>
        </div>

        {/* Active filters */}
        <div className="max-w-7xl mx-auto ">
          <div className=" py-3 px-4 sm:flex sm:items-center sm:px-6 lg:px-8">
            <h3 className="text-xs font-semibold uppercase tracking-wide text-gray-900">
              Filters
              <span className="sr-only">, active</span>
            </h3>

            <div aria-hidden="true" className="hidden w-px h-5 bg-gray-900 sm:block sm:ml-4" />

            <div className="mt-2 sm:mt-0 sm:ml-4 flex justify-end">
              <div className="-m-1 flex flex-wrap items-center">
                {activeFilters.map((activeFilter) => (
                  <span
                    key={activeFilter.value}
                    className="m-1 inline-flex bg-amber-400 rounded-full border border-gray-200 items-center py-1.5 pl-3 pr-2 text-sm font-medium bg-white text-gray-900"
                  >
                    <span>{activeFilter.name}: {activeFilter.label}</span>
                    <button
                      onClick={() => removeFromActiveFilters(activeFilter.value)}
                      type="button"
                      className="flex-shrink-0 ml-1 h-4 w-4 p-1  rounded-full inline-flex text-gray-900 hover:bg-gray-200 hover:text-gray-700"

                    >
                      <span className="sr-only">Remove filter for {activeFilter.label}</span>
                      <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                      </svg>
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

