// REDUX
import React, { Component } from "react";
import Navbar from '../Nav-Bar/nav-bar.component';
import HeroHome from './hero.components';
import Caroussel from './caroussel.component';
import { Footer } from '../Footer/footer.component';
import Searchbar from './searchbar.component';
import TestCaroussel from "./test.caroussel";
import LatestContent from "./latest.content.component";

import { Helmet } from 'react-helmet-async';
import SEO from "../SEO/seo.component";



class Home extends Component {
    constructor(props) {
        super(props);
        this.onUpdate = this.onUpdate.bind(this);
    }

    onUpdate(response) {
        console.log(response.content)
        this.setState({
            content: response.content.results,
            page_next_link: response.content.next
        });
    }


    render() {
        return (
            <div>
                <SEO title='mietplace.ch miete oder vermiete gratis'
                    description='miete oder vermiete dinge du besitzt'
                    name='mietplace.ch' />
                <Navbar activateFilter={false} onUpdate={this.onUpdate} />
                <TestCaroussel />
                <Searchbar />
                <HeroHome></HeroHome>
                <LatestContent></LatestContent>
                <Footer></Footer>
            </div>
        );
    }
}


export default (Home);