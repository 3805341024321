import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { Footer } from '../Footer/footer.component';
import ConfirmImage from './Images/mailconfirm.jpg'
function CheckMail() {

    const navigate = useNavigate();
    let { email } = useParams();


    useEffect(() => {
        setTimeout(() => {
            navigate('/')
        }, 7000)
    }, [])
    return <div>
        <div class="relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12 bg-white">
            <div class="max-w-xl px-5 text-center">
                <img src={ConfirmImage} alt="https://www.freepik.com/free-vector/email-campaign-concept-illustration_7472011.htm#query=email&position=15&from_view=search&track=sph#position=15&query=email" class=" mx-auto h-72 w-72" />
                <h2 class="mb-2 text-[42px] font-bold text-zinc-800">Email bestätigen</h2>
                <p class="mb-2 text-lg text-zinc-500">Wir freuen uns, dass Sie bei uns sind. Wir haben Ihnen einen Verifizierungslink an  <span class="font-medium text-indigo-500">{email}</span> geschickt.</p>
                <p class="mb-2 text-lg text-zinc-500">Sie werden in 7 Sekunden weitergeleitet</p>
                <p class="mb-2 text-lg text-zinc-500">oder klicken sie <a href='/' class="font-medium text-indigo-500">hier</a></p>
            </div>
        </div>
        <Footer></Footer>
    </div>

}

export default CheckMail