import React, { Component } from "react";
import Slider from "react-slick";
import GigService from '../../services/gig.service';
import CategoryCard from "./category.card.component";
import {
    MusicNoteIcon,
    BookOpenIcon,
    AcademicCapIcon,
    DesktopComputerIcon,
    CogIcon,
    TruckIcon,
    PencilIcon,
    HomeIcon,
    StopIcon,
    VideoCameraIcon,
    VariableIcon
  } from '@heroicons/react/outline'

  
const categories = [
    {
      name: 'Audiogeräte',
      description: 'Suche nach Equipments, Lautsprecher, Vynyl etc',
      href: '/list/category=Audio',
      cat: 'DM',
      icon: MusicNoteIcon,
    },
    {
      name: 'Bücher',
      description: 'Suche nach Bücher, Magazine, Literatur etc',
      href: '/list/category=Bücher',
      icon: BookOpenIcon,
    },
    {
      name: 'Computer',
      description: 'Suche nach Computerhardware, GPU, CPU etc',
      href: '/list/category=Computer',
      icon: DesktopComputerIcon,
    },
    {
      name: 'Diensleistungen',
      description: 'Suche nach Dienstleistungen',
      href: '/list/category=Diensleistungen',
      icon: CogIcon,
    },
    {
      name: 'Fahrzeuge',
      description: 'Suche nach Auto, Lieferwagen, Limousine etc',
      href: '/list/category=Fahrzeuge',
      icon: TruckIcon,
    },
    {
      name: 'Handwerk',
      description: 'Suche nach Bohrmaschine, Hochdruckreiniger etc',
      href: '/list/category=Handwerk',
      icon: PencilIcon,
    },
    {
      name: 'Immobilien',
      description: 'Suche nach Immobilien, Wohnungen, Büroflächen etc',
      href: '/list/category=Immobilien',
      icon: HomeIcon,
    },
    {
      name: 'Möbel',
      description: 'Suche nach Tische, Stühle etc',
      href: '/list/category=Möbel',
      icon: StopIcon,
    },
    {
      name: 'Kleidung',
      description: 'Suche nach Kostüme, Masken etc',
      href: '/list/category=Kleidung',
      icon: AcademicCapIcon,
    },
    {
      name: 'TV',
      description: 'Suche nach Projektoren, Bildschirme etc',
      href: '/list/category=TV',
      icon: VideoCameraIcon,
    },
    {
      name: 'Sonstiges',
      description: 'Suche nach diverse Objekte etc zum mieten ',
      href: '/list/category=Sonstiges',
      icon: VariableIcon,
    },
  ]

class TestCaroussel extends Component {
    constructor(props) {
        super(props);
        const content = []
        const loading = true
        this.state = { content, loading };
    }

    componentDidMount() {

        GigService.getLatestContent().then(
            response => {
                this.setState({
                    content: response.data,
                });
                this.setState({ loading: false })

            },
            error => {
                this.setState({
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                });


            }
        );
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 1,
            slidesToShow: 5,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            speed: 6000,
            autoplaySpeed: 2000,
            mobileFirst:true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 786,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        initialSlide: 3
                    }
                },
                {
                    breakpoint: 568,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                }
            ]
        };


        return (
            <div className="mx-auto max-w-7xl py-12">
                <div className="overflow-hidden">
                    <Slider {...settings}>
                    {categories?.map((category,index) => (
                        <div key={index}>
                            <div>
                            <CategoryCard category={category} ></CategoryCard>
                            </div>
                        </div>
                    ))}
                </Slider>
                </div>
                
            </div>
        );
    }
}





export default (TestCaroussel)