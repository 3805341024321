import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';
const customId = "custom-id-yes";

const AlertItem = ({ alert }) => {

  useEffect(() => {

    toast.configure();

    if (alert.alertType == "error") {
      //https://www.npmjs.com/package/react-toastify
      toast.error(alert.msg, {
        toastId: alert.id,
        position: "bottom-center",
        theme: "colored"
      })
    }

    if (alert.alertType == "success") {
      //https://www.npmjs.com/package/react-toastify
      toast.success(alert.msg, {
        toastId: alert.id,
        position: "top-center",
        theme: "colored"
      })
    }


    if (alert.alertType == "warning") {
      //https://www.npmjs.com/package/react-toastify
      toast.warning(alert.msg, {
        toastId: alert.id,
        theme: "colored"
      })
    }

    if (alert.alertType == "default") {
      //https://www.npmjs.com/package/react-toastify
      toast(alert.msg, {
        toastId: alert.id,
        position: "top-center",
        theme: "colored"
      })
    }

    if (alert.alertType == "info") {
      //https://www.npmjs.com/package/react-toastify
      toast.info(alert.msg, {
        toastId: alert.id,
        position: "top-center",
        theme: "colored"
      })
    }

    if (alert.alertType == "loading") {
      //https://www.npmjs.com/package/react-toastify
      toast.loading(alert.msg,
        {
          toastId: alert.id,
          position: "top-center",
        })
    }

  }, [alert]);

  return null
}


export default AlertItem;