import React from 'react'
import { DateRange } from 'react-date-range';
import { de } from 'react-date-range/src/locale/index'
import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { inquire } from '../../actions/gigs';
import moment from 'moment';

function ReactCalendar(props) {
    const navigate = useNavigate();

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);


    const [remarkText, setremarkText] = useState([]);


    const handleChange = (e) => {
        setremarkText(e.target.value)
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();
        console.log()
        if (!props.isAuthenticated) {
            navigate("/login")
            return
        }

        if (state[0].startDate != null && state[0].endDate != null) {
            var startDate = Date.parse(state[0].startDate)
            var endDate = Date.parse(state[0].endDate)

            console.log(startDate)
            console.log(endDate)


            let start_date = moment(new Date(startDate)).format('YYYY-MM-DD HH:mm:ss')
            let end_date = moment(new Date(endDate)).format('YYYY-MM-DD HH:mm:ss')

            console.log(start_date)
            console.log(end_date)
            props.inquire(start_date, end_date, remarkText, props.id)
        }

    }


    //https://hypeserver.github.io/react-date-range/#daterangepicker


    return (
        <div class="p-8">
            <h2 class="text-center text-gray-800 text-2xl font-bold pt-6"> unverbindliche Reservierungsanfrage</h2>

            <div class="w-5/6 m-auto">
                <p class="text-center text-gray-500 pt-5">Wähle ein Start und ein End Datum aus</p>
            </div>
            <DateRange
                editableDateInputs={true}
                onChange={item => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
                locale={de}
                endDatePlaceholder={"End Datum auswählen"}
            />

            <div class="grid grid-cols-4 w-72 lg:w-5/6 m-auto  p-4 lg:p-4 rounded-2xl">
                <input value={remarkText} onChange={handleChange} class=" w-72 focus:outline-none border-none focus:ring-0" type="text" placeholder="Nachricht für den Vermieter..." />
            </div>

            <div class="p-6">


                <form onSubmit={(event) => handleOnSubmit(event)} class="bg-blue-500 w-72 lg:w-5/6 m-auto mt-6 p-2 hover:bg-indigo-500 text-white text-center shadow-xl">
                    <button type='submit' classs="lg:text-sm text-lg font-bold">Mietanfrage senden</button>
                </form>

            </div>
        </div>
    )
}

ReactCalendar.propTypes = {
    isAuthenticated: PropTypes.bool,
    inquire: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
});


export default connect(mapStateToProps, { inquire })(ReactCalendar)
