import { useStepperContext } from "../stepper.context.component";
import FileUploaderWrapper from "./fileuploader.component";
import { useForm } from "react-hook-form";
import ReactQuill from 'react-quill';
import { useState } from 'react'
import 'react-quill/dist/quill.bubble.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './_details.scss'
import { GeoapifyGeocoderAutocomplete, GeoapifyContext } from '@geoapify/react-geocoder-autocomplete'
import '@geoapify/geocoder-autocomplete/styles/minimal.css'
import { RoundCorners } from "@cloudinary/url-gen/actions";
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};



export default function Details() {
  const { userData, setUserData } = useStepperContext();
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [error, setError] = useState()

  const [type, setType] = useState();
  const [language, setLanguage] = useState();
  const [position, setPosition] = useState();
  const [countryCodes, setCountryCodes] = useState();
  const [debounceDelay, setDebounceDelay] = useState();
  const [limit, setLimit] = useState();
  const [value, setValue] = useState('');
  const [filterByCountryCode, setFilterByCountryCode] = useState();
  const [filterByCircle, setFilterByCircle] = useState();
  const [filterByRect, setFilterByRect] = useState();
  const [filterByPlace, setFilterByPlace] = useState();
  const [biasByCountryCode, setBiasByCountryCode] = useState();
  const [biasByCircle, setBiasByCircle] = useState();
  const [biasByRect, setBiasByRect] = useState();
  const [biasByProximity, setBiasByProximity] = useState();


  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  function onPlaceSelect(value) {
    setUserData({
      ...userData, ["zip"]: value.properties.postcode,
      ["city"]: value.properties.city,
      ["canton"]:  value.properties.state,
      ["country"]:  value.properties.country,
      ["lat"]:  value.properties.lat,
      ["lon"]:  value.properties.lon
    });
  }

  function onSuggectionChange(value) {
    console.log(value);
  }

  function preprocessHook(value) {
    return `${value}, Munich, Germany`
  }

  function postprocessHook(feature) {
    return feature.properties.street;
  }

  function suggestionsFilter(suggestions) {
    const processedStreets = [];

    const filtered = suggestions.filter(value => {
      if (!value.properties.street || processedStreets.indexOf(value.properties.street) >= 0) {
        return false;
      } else {
        processedStreets.push(value.properties.street);
        return true;
      }
    })

    return filtered;
  }


  const handleDescription = (e) => {
    setUserData({ ...userData, ["description"]: e });

  }

  const handleConditions = (e) => {
    setUserData({ ...userData, ["conditions"]: e });
  }

  const handleCategories = (e) => {
    setUserData({ ...userData, ["category"]: e.target.value });
  }

  const checkNegativePricePerWeekNumber = (e) => {
    if (e.target.value < 0) {
      e.target.value = 0
    }
    else {
      setUserData({ ...userData, ["price_week"]: e.target.value });
    }
  }

  const checkNegativePricePerDayNumber = (e) => {
    if (e.target.value < 0) {
      e.target.value = 0
    }
    else {
      setUserData({ ...userData, ["price_day"]: e.target.value });
    }
  }

  const checkNegativePricePerHourNumber = (e) => {
    if (e.target.value < 0) {
      e.target.value = 0
    }
    else {
      setUserData({ ...userData, ["price_hour"]: e.target.value });
    }
  }

  const checkNegativeRentDuration = (e) => {
    if (e.target.value < 0) {
      e.target.value = 0
    }
    else {
      setUserData({ ...userData, ["rent_duration"]: e.target.value });
    }
  }


  return (
    <section class="bg-white dark:bg-gray-900">
      <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Jetzt kostenlos inserieren</h2>
        <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">Beschreibe dein Mietinserat hier</p>
        <form action="#" class="space-y-8 text-left">
          <div>
            <label class="block mb-2  font-medium text-gray-900 dark:text-gray-300">Wie lautet der Titel deines Mietinserat
            </label>
            <input
              class="shadow-sm border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              {...register("title", { required: "Erforderlich" })}
              onChange={handleChange}
              value={userData["title"] || ""}
              name="title" />
          </div>

          <div>
            <label for="subject" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Welches Kategorie passt dein Inserat am besten
            </label>
            <label for="underline_select" name="category" class="sr-only">Underline select</label>
            <select defaultValue={userData["category"] || ""} onChange={handleCategories} id="underline_select" class="block px-2.5 py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-1 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
              <option value={"Auswählen"}></option>
              <option value="Audio">Audio</option>
              <option value="Bücher">Bücher</option>
              <option value="Computer">Computer</option>
              <option value="Dienstleistungen">Dienstleistungen</option>
              <option value="Fahrzeuge">Fahrzeuge</option>
              <option value="Handwerk">Handwerk</option>
              <option value="Immobilien">Immobilien</option>
              <option value="Möbel">Möbel</option>
              <option value="Kleidung">Kleidung</option>
              <option value="TV">TV</option>
              <option value="Sonstiges">Sonstiges</option>
            </select>
          </div>

          <div>
            <label class="block mb-2  font-medium text-gray-900 dark:text-gray-300">Wo befindet sich das Mietobjekt ?
            </label>
            


            <GeoapifyContext apiKey="34f89ffc48ce4e66ae7762ed904208b7" >
              <GeoapifyGeocoderAutocomplete
                placeholder="Postleitzahl eingeben"
                value={value}
                type={type}
                lang={'de'}
                position={position}
                countryCodes={countryCodes}
                limit={'1'}
                filterByCountryCode={filterByCountryCode}
                filterByCircle={filterByCircle}
                filterByRect={filterByRect}
                filterByPlace={filterByPlace}
                biasByCountryCode={biasByCountryCode}
                biasByCircle={biasByCircle}
                biasByRect={biasByRect}
                biasByProximity={biasByProximity}
                placeSelect={onPlaceSelect}
                suggestionsChange={onSuggectionChange}
                skipIcons={true}
              />

            </GeoapifyContext>



          </div>

          <div>
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Beschreibe deinen Inserat</label>

            <div class="">
              <ReactQuill theme={"snow"} style={{ height: '200px', width: '100%' }} value={userData["description"] || ""} placeholder="Schreibe hier rein..." name="description" onChange={handleDescription} />
            </div>
          </div>

          <div class="py-12">
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Wie sieht des mit der Versicherung aus ? (Optional)
            </label>
            <div class="">
              <ReactQuill theme={"snow"} style={{ height: '200px', width: '100%' }} value={userData["conditions"] || ""} placeholder="Schreibe hier rein..." name="conditions" onChange={handleConditions} />
            </div>
          </div>

          <div class=" justify-center items-center w-full">
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Lade maximal 4 Bilder hoch</label>
            <label class="block mb-2  text-gray-700 dark:text-gray-200">Das 1.Bild wird als Hauptbild ausgewählt</label>
            <FileUploaderWrapper />

          </div>

          <div>
            <label class="block mb-2  font-medium text-gray-900 dark:text-gray-300">Wieviel kostet es pro Stunde
            </label>
            <input
              {...register("price_hour", { required: "Erforderlich" })}
              onChange={checkNegativePricePerHourNumber}
              value={userData["price_hour"] || ""}
              name="price_hour"
              type="number"
              class="shadow-sm  border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="CHF pro Stunde" required />
          </div>

          <div>
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Wieviel kostet es pro Tag (Optional)
            </label>
            <input {...register("price_day", { required: "Erforderlich" })}
              onChange={checkNegativePricePerDayNumber}
              value={userData["price_day"] || ""}
              name="price_day"
              type="number"
              class="shadow-sm  border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="CHF pro Stunde" required />
          </div>

          <div>
            <label class="block mb-2 font-medium text-gray-900 dark:text-gray-300">Wieviel kostet es pro Woche (Optional)
            </label>
            <input  {...register("price_week", { required: "Erforderlich" })}
              onChange={checkNegativePricePerWeekNumber}
              value={userData["price_week"] || ""}
              name="price_week"
              type="number"
              class="shadow-sm  border border-gray-300 text-gray-900  rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="CHF pro Stunde" required />
          </div>

          <div class="">
            <label for="email" class="block mb-2 font-medium text-gray-900 dark:text-gray-300">Was ist die Mindestdauer für die Miete (Optional)
            </label>
            <input  {...register("rent_duration", { required: "Erforderlich" })}
              onChange={checkNegativeRentDuration}
              value={userData["rent_duration"] || ""}
              name="rent_duration"
              type="number"
              class="shadow-sm border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Anzahl Stunden" required />
          </div>
        </form>
      </div>
    </section>
  );
}