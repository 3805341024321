import React from 'react'
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { likeGig } from '../../actions/gigs';

function findById(source, id) {
  for (var i = 0; i < source.length; i++) {

    if (source[i].user === id) {
      return true;
    }
  }
  return false;
}

function LikeButton(props,) {
  const navigate = useNavigate();





  const OnLikeButton = (e) => {


    if (!props.isAuthenticated) {
      navigate("/login")
      return
    }

    props.likeGig(props.contentID)

  }



  if (findById(props.likes, props.userID)) {
    return (
     <button  onClick={() => OnLikeButton()} class="relative inline-block text-6xl text-white">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-red-500 fill-transparent hover:fill-red-500 fill-red-500" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
          </svg>
            <span
                class="absolute top-0 right-0 px-2 py-1 translate-x-1/2 bg-red-500 border border-white rounded-full text-xs text-white">{props.noLikes}</span>
        </button>
    )
  }

  return (
    <div>
        

        <button  onClick={() => OnLikeButton()} class="relative inline-block text-6xl text-white">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-red-500 fill-transparent hover:fill-red-500" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
          </svg>
            <span
                class="absolute top-0 right-0 px-2 py-1 translate-x-1/2 bg-red-500 border border-white rounded-full text-xs text-white">{props.noLikes}</span>
        </button>
    </div>
  )
}

LikeButton.propTypes = {
  isAuthenticated: PropTypes.bool,
  likeGig: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { likeGig })(LikeButton)