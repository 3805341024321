import React, { Component } from 'react'
import Navbar from '../Nav-Bar/nav-bar.component'
import GigService from '../../services/gig.service';
import EventBus from '../../common/EventBus';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'tw-elements';
import ReactCalendar from './calendar.component';
import Comment from './comment.component';
import MapTest from '../Map/mapTest';
import LikeButton from './like.button.component'
import DislikeButton from './dislike.button.component';
import { Footer } from '../Footer/footer.component';
import Slider from "react-slick";
import CloudinaryWrapper from '../../common/cloudinary.wrapper.component';
import Searchbar from './searchbar.component';
import FavouriteButtonComponent from './favourite.button.component';
import user from './images/user.svg'
import aperture from './images/aperture.svg'
import mappin from './images/map-pin.svg'



class Product extends Component {

    constructor(props) {
        super(props);
        const content = []
        const notification = []
        const index = 0
        const loading = false
        const isOpen = false
        const currentPicture = ""
        this.state = { content, index, loading, isOpen, notification, redirect: false, currentPicture }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.buttonHandler = this.buttonHandler.bind(this);
        this.reserveRef = React.createRef();
    }



    componentDidMount() {
        this.setState({ loading: true })
        let id = this.props.params.slug;
        GigService.getGig(id).then(
            response => {
                this.setState({
                    content: response.data
                });
                EventBus.dispatch("ALLGIGS_LOADED")


            },

            error => {
                this.setState({
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                });

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("ALLGIGS_FAILED");
                }
            }
        );


        this.setState({ loading: false })
    }


    closeModal() {
        console.log("close modal")
        this.setState(prevState => ({ isOpen: false }))
    }

    openModal() {
        console.log("Open modal")
        this.setState(prevState => ({ isOpen: true }))
    }

    buttonHandler() {
        alert('Hi there!');
    }



    //https://tailwind-elements.com/docs/standard/components/carousel/

    render() {

        var settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };


        if (this.state.content.length === 0) {

            return (
                <div class="flex items-center justify-center w-full h-full">
                    <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">

                        <svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
                            <path clip-rule='evenodd'
                                d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                                fill='currentColor' fill-rule='evenodd' />
                        </svg>

                        <div>Loading ...</div>
                    </div>
                </div>
            )
        }

        return (

            <div class="overflow-hidden">

                <div>
                    <Navbar></Navbar>
                </div>

                <div>
                    <Searchbar></Searchbar>
                </div>

                <div>
                    <button div onClick={() => this.reserveRef.current.scrollIntoView({ behavior: "smooth" })} title="Contact Sale"
                        class="fixed md:hidden z-10 bottom-0 right-3 flex break-inside bg-gray-900 text-white border-2 shadow-2xl border-transparent rounded-3xl px-6 py-3 mb-4 w-32 dark:bg-slate-800 dark:text-white">
                        <div class='m-auto'>
                            <div class='flex items-center justify-start flex-1 space-x-4'>
                                <span class='font-medium mb-[-3px]'>Reservieren</span>
                            </div>
                        </div>
                    </button>
                </div>

                {/* Modal Picture  */}
                <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenteredScrollable" aria-modal="true" role="dialog">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                            <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalCenter">
                                    {this.state.content.title}
                                </h5>
                                <button type="button"
                                    class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                                    data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body relative p-4">
                                <img src={this.state.currentPicture} alt={this.state.currentPicture} />

                            </div>
                        </div>
                    </div>
                </div>



                {/** First Container full width and spacing */}
                <div class="relative lg:flex space-y-8 max-w-7xl  mx-auto ">

                    {/** 1 Column */}
                    <div class="mx-auto space-y-8 md:w-3/5">

                        {/** This div contains picture and descripton */}
                        <div class="pt-24">



                            <Slider class="z-0" {...settings}>
                                {this.state.content.images?.map(images => (
                                    <div class="px-6">
                                        <CloudinaryWrapper updateCurrentPicture={(currentPicture) => this.setState({ currentPicture })} image={images.image} width={350} height={600} />
                                    </div>
                                ))}
                            </Slider>


                            <div class="">
                            </div>



                            {/* Product description  */}
                            <section class="bg-white dark:bg-gray-900 ">

                                <div class=" text-left py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 space-y-2">

                                    <div class='md:text-lg flex justify-around '>
                                        <div class="m-4 flex flex-col items-center">
                                            <FavouriteButtonComponent contentID={this.state.content.id}></FavouriteButtonComponent>
                                            <p class="">Merken</p>
                                        </div>
                                        <div class="m-4 flex flex-col items-center">
                                            <LikeButton noLikes={this.state.content.likes.length} contentID={this.state.content.id} likes={this.state.content.likes} userID={this.props.id}></LikeButton>
                                            <p class="">Like</p>
                                        </div>
                                        <div class="m-4 flex flex-col items-center">
                                            <DislikeButton noDislikes={this.state.content.dislikes.length} contentID={this.state.content.id} dislikes={this.state.content.dislikes} userID={this.props.id}></DislikeButton>
                                            <p class="">Nope</p>
                                        </div>
                                    </div>


                                    <div class="border border-gray-400 border-dashed rounded-2xl w-full">

                                        <div class="flex justify-between py-4 px-6 ">
                                            <div class="flex flex-wrap">
                                                <div class="p-2">
                                                    <button class=" bg-blue-100 p-2 h-8 inline-flex items-center rounded-lg shadow-xl">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-5 h-5 mr-2 text-indigo-700">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>

                                                        <span class="text-sm text-gray-900 font-bold">{this.state.content.create_time} </span>
                                                    </button>
                                                </div>

                                                <div class="p-2 ">
                                                    <button class=" bg-yellow-100 p-2 h-8 inline-flex items-center rounded-lg shadow-xl">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" class="w-5 h-5 mr-2 text-gray-700  ">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                                                        </svg>

                                                        <span class="text-sm text-gray-700 font-bold ">Zum Vermieten </span>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>



                                        <div className='px-8 flex justify-between' >
                                            <p class=" text-4xl break-words font-bold text-gray-900 dark:text-white ">{this.state.content.title} </p>
                                        </div>

                                        <div class="md:flex justify-between py-4 md:py-8 px-8">
                                            <a class="flex md:flex-col items-center ">
                                                <div>
                                                    <img class="w-4 h-4 md:w-8 md:h-8" src={user} />
                                                </div>

                                                <div>
                                                    <span class=" md:text-lg font-bold text-gray-500 px-2">Inserant:
                                                    </span>
                                                </div>

                                                <div>
                                                    <span class="md:text-lg md:px-2 text-gray-900 font-bold px-8 "> {this.state.content.username}</span>
                                                </div>
                                            </a>

                                            <div class="flex md:flex-col  items-center ">
                                                <div>
                                                    <img class="w-4 h-4 md:w-8 md:h-8" src={aperture} />
                                                </div>
                                                <div>
                                                    <span class="md:text-lg  text-gray-500 px-2"> Kategorie:
                                                    </span>
                                                </div>
                                                <div>
                                                    <span class="md:text-lg md:px-0  text-gray-900 px-7"> {this.state.content.category}</span>
                                                </div>
                                            </div>

                                            <div class=" flex md:flex-col items-center ">
                                                <div>
                                                    <img class="w-4 h-4 md:w-8 md:h-8 " src={mappin} />
                                                </div>

                                                <div>
                                                    <span class="md:text-lg  text-gray-500 px-2"> Standort:
                                                    </span>
                                                </div>
                                                <div>
                                                    <span class="md:text-lg text-xs text-gray-900 px-8 md:px-0"> {this.state.content.zip} {this.state.content.city}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="border w-full px-8 py-8  border-gray-400 border-dashed rounded-2xl ">

                                        <div class="flex justify-between py-2 ">

                                            <div className='flex justify-between ' >
                                                <p class=" text-2xl break-all font-bold text-gray-900 dark:text-white ">Inseratbeschreibung </p>
                                            </div>

                                        </div>



                                        <div>
                                            <p class="py-2 text-lg break-normal break-all">
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: this.state.content.description }}                                                >
                                                </div>
                                            </p>

                                        </div>


                                    </div>

                                    <div class="border w-full px-8 py-8  border-gray-400 border-dashed rounded-2xl">

                                        <div class="flex justify-between  ">

                                            <div className='flex justify-between ' >
                                                <p class=" text-2xl break-all font-bold text-gray-900 dark:text-white ">Tarif </p>
                                            </div>

                                        </div>

                                        {/* Tabel of Prices */}
                                        <div class="flex flex-col">
                                            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                                    <div class="overflow-x-auto">
                                                        <table class="min-w-full">
                                                            <thead class="border-b ">
                                                                <tr>
                                                                    <th scope="col" class="md:text-lg font-medium text-gray-900 px-2 py-4 text-left">
                                                                        Pro Stunde
                                                                    </th>
                                                                    <th scope="col" class="md:text-lg font-medium text-gray-900 px-2 py-4 text-left">
                                                                        Pro Tag
                                                                    </th>
                                                                    <th scope="col" class="md:text-lg  font-medium text-gray-900 px-2 py-4 text-left">
                                                                        Pro Woche
                                                                    </th>
                                                                    <th scope="col" class="md:text-lg  font-medium text-gray-900 px-2 py-4 text-left">
                                                                        Mindestdauer
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr class="border-b">
                                                                    {this.state.content.price_hour > 0 &&
                                                                        <td class="md:text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                            {this.state.content.price_hour}.-
                                                                        </td>
                                                                    }

                                                                    {this.state.content.price_hour == 0 &&
                                                                        <td class="md:text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                            -
                                                                        </td>
                                                                    }

                                                                    {this.state.content.price_day > 0 &&
                                                                        <td class="md:text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                            {this.state.content.price_day}.-
                                                                        </td>
                                                                    }

                                                                    {this.state.content.price_day == 0 &&
                                                                        <td class="md:text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                            -
                                                                        </td>
                                                                    }

                                                                    {this.state.content.price_week > 0 &&
                                                                        <td class="md:text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                            {this.state.content.price_week}.-
                                                                        </td>
                                                                    }

                                                                    {this.state.content.price_week == 0 &&
                                                                        <td class="md:text-lg text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                            -
                                                                        </td>
                                                                    }


                                                                    <td class="md:text-lg  text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                        {this.state.content.rent_duration}h
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="border w-full px-8 py-2  border-gray-400 border-dashed rounded-2xl">

                                        <div class="flex justify-between py-2 ">

                                            <div className='flex justify-between ' >
                                                <p class=" text-2xl break-all font-bold text-gray-900 dark:text-white ">Versicherung/AGB</p>
                                            </div>

                                        </div>


                                        <div>

                                            <p class="py-2 text-lg break-normal break-all">
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: this.state.content.conditions }}                                                >
                                                </div>
                                            </p>
                                        </div>
                                    </div>




                                    <div class="border w-full px-8 py-8  border-gray-400 border-dashed rounded-2xl">

                                        <div class=" ">

                                            <div className='flex justify-between ' >
                                                <p class=" text-2xl break-all font-bold text-gray-900 dark:text-white ">Standort </p>
                                            </div>

                                            <div>
                                                <span class="md:text-lg  text-gray-900 font-medium"> {this.state.content.zip} {this.state.content.city}</span>
                                                <p className='md:text-lg  text-gray-700'>
                                                    Der Vermieter teilt mit ihnen persöhnlich die genaue Adresse .
                                                </p>
                                            </div>

                                        </div>


                                        <div>
                                            {/* Map */}
                                            <div class="max-w-7xl mx-auto">
                                                <MapTest style={{ height: '200px', zIndex: '1' }} content={this.state.content} listing={false} />
                                            </div>
                                        </div>
                                    </div>


                                    <div class="border w-full px-8 py-2  border-gray-400 border-dashed rounded-2xl">

                                        {/* Comment */}
                                        <div class="max-w-7xl mx-auto">
                                            <Comment id={this.state.content.id} reviews={this.state.content.review}></Comment>
                                        </div>




                                    </div>
                                </div>
                            </section>
                        </div>
                        <div>
                        </div>
                    </div>

                    {/** 2 Column */}
                    <div class=" mx-auto space-y-8">

                        {/* Reservation */}
                        <div ref={this.reserveRef} className="inline-block md:text-lg  shadow-2xl lg:max-w-md overflow-hidden align-middle transition-all transform bg-white ">
                            <ReactCalendar id={this.state.content.id} />
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div >
        )

    }
}


export default (Product)
