import React, { useState } from 'react';
import { SearchIcon } from '@heroicons/react/solid'
import Typewriter from 'typewriter-effect';
import HeroImage from './images/hero.svg'

function HeroHome() {

  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
<section className="dark:bg-gray-800 dark:text-gray-100">
	<div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between">
		<div className="flex flex-col justify-center mx-auto   text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
			<h1 className="text-5xl font-adineue font-bold leading-none sm:text-6xl">miete oder 
				<span className="dark:text-violet-400"></span> vermiete
			</h1>
			<div className="mt-6 mb-8 text-xl font-adineue px-2 sm:mb-12">Verdiene Geld indem
                    <Typewriter
                        options={{
                            strings: ['dein Auto', 'deine Möbel', 'dein Fernseher', 'dein Beamer', 'deine Kleider', 'deine Werkzeug'],
                            autoStart: true,
                            loop: true,
                        }}
                    />
                    vermietest

			</div>

			<div className="mt-6 mb-8 text-xl  font-adineue px-2 sm:mb-12">
                    Jetzt kostenlos Mietobjekte inserieren
					oder nach  Mietobjekten kostenlos suchen
			</div>

			
		</div>
		<div className="flex items-center mx-auto justify-center p-6 mt-8 lg:mt-0 h-48 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
			<img src={HeroImage} alt="" className="object-contain h-48 sm:h-80 lg:h-96 xl:h-112 2xl:h-128" />
		</div>
	</div>
</section>

  );
}

export default HeroHome;