import React from 'react'
import NavBarComponent from '../Nav-Bar/nav-bar.component'
import { Footer } from '../Footer/footer.component'

function FAQ() {
    return (
        <div>

            <NavBarComponent></NavBarComponent>

            <section class="bg-white dark:bg-gray-900">
                <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                    <h2 class="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Frequently asked questions</h2>
                    <div class="grid pt-8 text-left border-t border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
                        <div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Ist mietplace.ch gratis ?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Ja, mietplace ist absolut gratis, du kannst gratis inserieren oder Mietanfragen erstellen.
                                </p>
                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Kostet die Registrierung was?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400"> Nein, Registrierung ist kostenlos </p>
                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Wie kann ich mich anmelden ?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Klicke auf deinen Namen oben links und dann Ausloggen
                                </p>
                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Wie kann ich den Vermieter eines Mietinserate kontaktieren ?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Im Inserat findest auf der rechten Seite ein Kontaktformular wo du das gewünschte Datum eintragen kannst und zusätzlich eine Nachricht am Vermieter send kannst.
                                </p>
                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Wie und wann bezahle ich meine Miete
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Wie and wann du die Miete bezahlst muss du dem Vermieter abmachen. mietplace.ch ist nur der Vermittler.</p>
                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Wie und wann bekomme ich das Geld ?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">
                                    Wie and wonn das Geld bekommst muss du mit den Kunden abmachen.
                                    mietplace.ch ist der Vermittler.</p>
                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Wie kann ich einen Artikel suchen
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Auf jeder Seite findest du eine Suchleiste, da kannst du Suchbegriff eingeben.</p>
                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Wie kann ich einen Artikel mieten
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400"> Indem du eine Mietanfrage erstellst im Inserat. Der Vermieter wird dich dann per Mail oder per Telefon kontaktieren.
                                </p>
                            </div>

                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Kostet die Mietanfrage was ?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Mietanfragen sind kostenlos und unverbindlich</p>

                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Wie storniere die Mietanfrage ?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Du kannst den Vermieter mitteilen dass du die Anfragen stornieren möchtest solange vertraglich nichts entstanden ist </p>

                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Fallen Gebühren an wenn die Miete verlängert oder storniert wird ?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Allfällige Gebühren bei Verlängerungen oder Stornierung wird vom Vermieter mitgeteilt.</p>

                            </div>
                         
                        </div>


                        <div>
                        <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Wie kann ich Inserate bewerten ?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Du kannst bewerten indem du den Inserat ein Like gibsts. Falls du negativ Erfahrung gemacht hast kannst du nope geben.</p>

                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Wie kann ich ein Kommentar zu einem Inserat abgeben ?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Kommentare kannst du am Ende des Inserats abgeben. Bitte denke an die Community Richtlininen beim kommentieren.</p>

                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Wie kann ich ein Mietinserat erstellen ?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Am Anfang der Seite kannst du auf das gratis inserieren button drücken.
                                    Ein Registrierung ist erforderlich um Inserate zu erstellen.</p>

                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Kostet das Mietinserat was ?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Es kostest absolut nichts um Mietinserate zu erstellen</p>

                            </div>

                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Wie lösche ich mein Inserat?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Indem auf deinem Namen und auf meine inserate drückst kannst du dort einzelne Mietinserate löschen oder bearbeiten</p>

                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Wo finde ich meine erstellten Inserate
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Indem auf deinem Namen und auf meine Inserate drückst </p>
                                <p class="text-gray-500 dark:text-gray-400">With that being said, feel free to use this design kit for your open-source projects.</p>
                                <p class="text-gray-500 dark:text-gray-400">Find out more information by </p>
                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Kann ich mein Mietinserat nachhinein bearbeiten ?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Indem auf deinem Namen und auf meine inserate drückst kannst du dort einzelne Mietinserate bearbeiten

                                </p>
                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Kann ich meine Mietinserate löschen ?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Indem auf deinem Namen und auf meine inserate drückst kannst du dort einzelne Mietinserate löschen</p>

                            </div>
                            <div class="mb-10">
                                <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                                    <svg class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    Wie kann ich mein Konto löschen ?
                                </h3>
                                <p class="text-gray-500 dark:text-gray-400">Um dein Konto zu löschen kannst eine Mail an info@mietplace.ch schreiben</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer></Footer>
        </div>
    )
}

export default FAQ