import React from 'react'
import ReactCalendar from '../../Product/calendar.component'

function Preview(props) {

  console.log(props)
  return (
    <div class="relative lg:flex space-y-8 max-w-7xl  mx-auto ">

      {/** 1 Column */}
      <div class="mx-auto space-y-8 md:w-3/5">

        {/** This div contains picture and descripton */}
        <div class=" ">
          {/**Profile photo */}


          {/* Pictures */}




          {/* Product description  */}
          <section class="bg-white dark:bg-gray-900 ">
            <div class=" text-left py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 space-y-8">



              <div class="border w-full">
                <div class="flex justify-between py-12 px-6 space-x-8">
                  <div class="flex flex-wrap ">



                    <button class=" bg-blue-100 p-2 h-8 inline-flex items-center ">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-5 h-5 mr-2 text-indigo-700">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>

                      <span class="text-sm text-indigo-700 font-bold">xx.xx.xx</span>
                    </button>


                    <button class=" bg-yellow-100 p-2 h-8 inline-flex items-center ">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" class="w-5 h-5 mr-2 text-gray-700  ">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                      </svg>

                      <span class="text-sm text-gray-700 font-bold">Zum Vermieten </span>
                    </button>


                  </div>


                  <div class="">
                    <button class="space-x-2 flex text-gray-800 font-bold  px-4 rounded-r">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-10 h-10">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                      </svg>
                    </button>
                  </div>


                </div>




                <div className='px-8 flex justify-between ' >
                  <p class=" text-4xl break-all font-bold text-gray-900 dark:text-white ">{props.content.content.title} </p>
                </div>

                <div class="flex justify-between py-4">

                  <a class=" px-8  flex items-center ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-10 h-10 mr-2 text-gray-500">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>

                    <span class="text-lg  text-gray-500"> Inseriert von
                      <span class="text-lg  text-gray-900 font-bold"> xxxxx</span>

                    </span>
                  </a>


                  <div>
                    <a class=" px-8  flex items-center ">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-8 h-8 mr-2 text-gray-00">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                      </svg>


                      <span class="text-lg  text-gray-500"> Standort:
                        <span class="text-lg  text-gray-900 "> {props.content.content.zip} {props.content.content.location}</span>
                      </span>
                    </a>

                  </div>





                </div>

              </div>



              <div class="border w-full px-8 py-8">

                <div class="flex justify-between py-8  space-y-8">

                  <div className='flex justify-between ' >
                    <p class=" text-2xl break-all font-bold text-gray-900 dark:text-white ">Inseratbeschreibung </p>
                  </div>

                </div>

                <div class="border px-8  w-32">
                </div>

                <div>
                  <p class="py-8 text-lg">
                    <div
                      dangerouslySetInnerHTML={{ __html: props.content.content.description }}                                                >
                    </div>
                  </p>

                </div>


              </div>

              <div class="border w-full px-8 py-8">

                <div class="flex justify-between py-8  space-y-8">

                  <div className='flex justify-between ' >
                    <p class=" text-2xl break-all font-bold text-gray-900 dark:text-white ">Versicherung/AGB </p>
                  </div>

                </div>
                <div class="border px-8  w-32">
                </div>

                <div>

                  <p class="py-8 text-lg">
                    <div
                      dangerouslySetInnerHTML={{ __html: props.content.content.conditions }}                                                >
                    </div>
                  </p>
                </div>
              </div>

              <div class="border w-full px-8 py-8">

                <div class="flex justify-between py-8  space-y-8">

                  <div className='flex justify-between ' >
                    <p class=" text-2xl break-all font-bold text-gray-900 dark:text-white ">Tarif </p>
                  </div>

                </div>



                {/* Tabel of Prices */}
                <div class="">
                  <table class="min-w-full">
                    <thead>
                      <tr class="text-lg text-left text-gray-900 bg-gray-100  border-b border-gray-600">
                        <th class="px-4 py-3">Pro Stunde</th>
                        <th class="px-4 py-3">Pro Tag</th>
                        <th class="px-4 py-3">Pro Woche</th>
                        <th class="px-4 py-3">Mindestdauer</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <tr class="text-gray-700 text-lg">
                        <td class="px-4 py-3 border text-md ">
                          {props.content.content.hour}.-
                        </td>
                        <td class="px-4 py-3 text-lg  border">{props.content.content.day}.-
                        </td>
                        <td class="px-4 py-3 text-lg border">
                          {props.content.content.price_week}.- </td>
                        <td class="px-4 py-3 text-lg border">{props.content.content.week}h</td>
                      </tr>
                    </tbody>
                  </table>


                </div>


              </div>


              <div class="border w-full px-8 py-8">

                <div class="flex justify-between py-8  space-y-8">

                  <div className='flex justify-between ' >
                    <p class=" text-2xl break-all font-bold text-gray-900 dark:text-white ">Standort </p>
                  </div>

                </div>
                <div class="border px-8  w-32">
                </div>

                <div>


                  {/* Map */}
                  <div class="max-w-7xl mx-auto">

                  </div>
                </div>
              </div>


              <div class="border w-full px-8 py-8">

                {/* Comment */}
                <div class="max-w-7xl mx-auto">

                </div>






              </div>
            </div>
          </section>
        </div>
        <div>
        </div>



      </div>


      {/** 2 Column */}
      <div class=" mx-auto space-y-8">

        {/* Reservation */}
        <div className="inline-block md:text-lg border shadow-2xl lg:max-w-md overflow-hidden align-middle transition-all transform bg-white ">


          <ReactCalendar  />
        </div>

      </div>
    </div>
  )
}

export default Preview