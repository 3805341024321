import React from 'react'
import Create from './create.component'
import Navbar from "../Nav-Bar/nav-bar.component";
import { UseContextProvider } from "./stepper.context.component";
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

function CreateContext({isAuthenticated}) {
    return (
        <div>
            <Navbar/>
            <UseContextProvider>
                <Create />
            </UseContextProvider>
        </div>

    )
}

CreateContext.propTypes = {
    isAuthenticated: PropTypes.bool,
  };
  
  const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
  });

  export default connect(mapStateToProps) (CreateContext)